import React, { useState, useEffect } from 'react'
import '../../pages/less/List.less'
import { Table, Button, Form, Input, Modal, message, Space, Upload,Select } from 'antd'
import { QueryProductListApi, SaveProductApi, DeleteProductByIdApi, QueryProductByIdApi, EditProductApi } from '../../request/api'
import { SearchOutlined, PlusCircleOutlined, EditOutlined, DeleteOutlined, FileAddOutlined, LoadingOutlined  } from '@ant-design/icons'
import 'moment/locale/zh-cn'
import defaultPhoto from '../../assets/defaultFace.jpg'

function AddPruduct(props) {
  const [form] = Form.useForm();

  const handleOk = async () => {
    const e = await form.validateFields();
    const { result, msg } = await SaveProductApi({
      productName: e.productName,
      productUrl: e.productUrl,
      productDesc: e.productDesc,
      productType: e.productType
    });
    if (result) {
      message.success(msg)
      props.handleOk(e);
    } else {
      message.error(msg)
    }
  }

  useEffect(() => {
    form.resetFields();
  }, [props.open, form])


  return (
    <Form form={form} labelCol={{ span: 4 }} name="control-hooks" >
      <Modal title="新增产品" open={props.open} onCancel={props.handleCancel} onOk={handleOk}>
        <span>
          <Form.Item label="产品名称" name="productName"   rules={[{ required: true, message: '请输入产品名称!' }]}>
            <Input placeholder="请输入产品名称" size='' />
          </Form.Item>
        </span>
        <span>
          <Form.Item label="产品类型" name="productType" rules={[{ required: true, message: '请输入产品类型!' }]}>
            <Select placeholder="请选择">
              <Select.Option value="1">基建</Select.Option>
              <Select.Option value="2">房建</Select.Option>
              <Select.Option value="3">翻样</Select.Option>
            </Select>
          </Form.Item>
        </span>
        <span>
          <Form.Item label="产品地址" name="productUrl"  >
            <Input placeholder="请输入产品地址" />
          </Form.Item>
        </span>
        <span>
          <Form.Item label="产品描述" name="productDesc" >
            <textarea placeholder="请输入产品描述" />
          </Form.Item>
        </span>
      </Modal>
    </Form>
  )
}

function EditProduct(props) {
  const [form] = Form.useForm();
  const handleOk = async () => {
    const e = await form.validateFields();
    const { result, msg } = await EditProductApi({
      id: props.id,
      productName: e.productName,
      photoUrl: e.photoUrl,
      productDesc: e.productDesc,
      productType: e.productType,
      productUrl: e.productUrl
    });
    if (result) {
      message.success(msg)
      props.handleOk(e);
    } else {
      message.error(msg)
    }
  }

  if (props.id) {
    QueryProductByIdApi({
      id: props.id
    }).then((res) => {
      if (res.result) {
        let data = res.data;
        const { productName, productUrl, productDesc, id,productType } = data;
        form.setFieldsValue({ productName, productUrl, productDesc, id,productType })
      } else {
        message.error(res.msg)
      }
    })
  }

  useEffect(() => {
    form.resetFields();
  }, [props.open, form])
  return (
    <Form form={form} labelCol={{ span: 4 }} name="control-hooks" >
      <Modal title="编辑产品" open={props.open} onCancel={props.handleCancel} onOk={handleOk}>
        <span>
          <Form.Item label="产品名称" name="productName"  rules={[{ required: true, message: '请输入产品名称!' }]}>
            <Input placeholder="请输入产品名称" size='' />
          </Form.Item>
        </span>
        <span>
          <Form.Item label="产品类型" name="productType" rules={[{ required: true, message: '请输入产品名称!' }]}>
            <Select placeholder="请选择">
              <Select.Option value="1">基建</Select.Option>
              <Select.Option value="2">房建</Select.Option>
              <Select.Option value="3">翻样</Select.Option>
            </Select>
          </Form.Item>
        </span>
        <span>
          <Form.Item label="产品地址" name="productUrl"  >
            <Input placeholder="请输入产品地址" />
          </Form.Item>
        </span>
        <span>
          <Form.Item label="产品描述" name="productDesc" >
            <textarea placeholder="请输入产品描述" />
          </Form.Item>
        </span>
      </Modal>
    </Form>
  )
}

function ProductDetail(props) {
  const [form] = Form.useForm()
  const [photoUrl1, setPhotoUrl1] = useState(null)

  if (props.id) {
    QueryProductByIdApi({
      id: props.id
    }).then((res) => {
      if (res.result) {
        let data = res.data;
        const { productName, productUrl, productDesc, id, photoUrl,productType } = data;
        if (photoUrl) {
          setPhotoUrl1(photoUrl)
        } else {
          setPhotoUrl1(defaultPhoto)
        }
        form.setFieldsValue({ productName, productUrl, productDesc, id,productType })
      } else {
        message.error(res.msg)
      }
    })
  }
  useEffect(() => {
    form.resetFields();
  }, [props.open, form])
  return (
    <Form form={form} labelCol={{ span: 4 }} name="control-hooks" >
      <Modal title="产品详情" open={props.open} onCancel={props.handleCancel} >
        <span>
          <Form.Item label="产品名称" name="productName"  >
            <Input placeholder="请输入产品名称" size='' />
          </Form.Item>
        </span>
        <span>
          <Form.Item label="产品类型" name="productType">
            <Select placeholder="请选择">
              <Select.Option value="1">基建</Select.Option>
              <Select.Option value="2">房建</Select.Option>
              <Select.Option value="3">翻样</Select.Option>
            </Select>
          </Form.Item>
        </span>
        <span>
          <Form.Item label="产品图片" name="photoUrl" >
            <img src={photoUrl1} alt="" style={{ 'width': '80px', 'height': '80px' }} />
          </Form.Item>
        </span>
        <span>
          <Form.Item label="产品地址" name="productUrl"  >
            <Input placeholder="请输入产品地址" />
          </Form.Item>
        </span>
        <span>
          <Form.Item label="产品描述" name="productDesc" >
            <textarea placeholder="请输入产品描述" />
          </Form.Item>
        </span>
      </Modal>
    </Form>
  )
}

function ModifyPhoto(props) {
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState();

  useEffect(() => {
    setImageUrl(props.src)
  }, [props.src])

  const handleChange = (info) => {
    if (info.file.status === 'uploading') {
      setLoading(true);
      return;
    }
    if (info.file.status === 'done') {
      getBase64(info.file.originFileObj, (url) => {
        setLoading(false);
        setImageUrl(url);
        //编辑产品信息
        console.log(info.file.response.data)
        EditProductApi({
          id: props.id,
          photoUrl: info.file.response.data,
        }).then((res) => {
          console.log('res:', res);
          if (res.result) {
            message.success(res.msg)
          } else {
            message.error(res.data)
          }
        })
      });
    }
  }
  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  };
  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg';
    if (!isJpgOrPng) {
      message.error('只能上传png、jpg和jpeg格式的图像!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error('图片的大小不能超过2M!');
    }
    return isJpgOrPng && isLt2M;
  };
  const uploadButton = (
    <div>
      {loading && <LoadingOutlined />}
      <div
        style={{
          marginTop: 8,
          height: '240px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column'
        }}
      >
        <p>点击或拖拽到此处上传</p>
        <p>支持 2M 以内的 .JPG .PNG 图片格式</p>
      </div>
    </div>
  );

  const handleOk = () => {
    if (imageUrl === props.src) {
      props.handleCancel()
    } else {
      localStorage.setItem('photoUrl', imageUrl)
      props.handleOk(imageUrl);
      // console.log(imageUrl)
    }
  }
  return (
    <Modal title="修改头像" width={432} open={props.open} onCancel={props.handleCancel} onOk={handleOk}>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Upload.Dragger
          name="file"
          listType="picture-card"
          className="avatar-uploader"
          showUploadList={false}
          style={{
            width: '280px',
            height: '280px',
          }}
          action="https://uc-api-test.rmes.cn/auth/auth/v1/pandora/business/auth/file/uploadFile"
          beforeUpload={beforeUpload}
          onChange={handleChange}
        >
          {uploadButton}
        </Upload.Dragger>
        <div style={{ float: 'left' }}>
          <img src={imageUrl} alt="" style={{ width: '80px', height: '80px' }} />
          <p style={{ opacity: 0.5 }}>头像预览</p>
        </div>
      </div>
    </Modal>

  );
}

function ProductList() {
  const [saveProduct, setSaveProduct] = useState(false);
  const [modifyProduct, setModifyProduct] = useState(false);
  const [queryProduct, setQueryProduct] = useState(false);
  const [modifyPhoto, setModifyPhoto] = useState(false);
  const [id, setId] = useState();
  //产品列表数组
  const [arr, setArr] = useState();

  //请求产品列表
  useEffect(() => {
    QueryProductListApi({
    }).then((res) => {
      if (res.result) {
        let newArr = undefined
        if (res.data === null) {
          newArr = null
        } else {
          newArr = JSON.parse(
            JSON.stringify(res.data.data)
          )
        }
        setArr(newArr)
      }
    })
  }, [])
  const onFinish = (values) => {
    QueryProductListApi({
      productName: values.productName
    }).then((res) => {
      // console.log(res)
      if (res.result) {
        let newArr = undefined
        if (res.data === null) {
          newArr = null
        } else {
          newArr = JSON.parse(
            JSON.stringify(res.data.data)
          )
        }
        setArr(newArr)
      }
    })
  }

  const queryProductList = () => {
    QueryProductListApi({
    }).then((res) => {
      // console.log(res)
      if (res.result) {
        let newArr = undefined
        if (res.data === null) {
          newArr = null
        } else {
          newArr = JSON.parse(
            JSON.stringify(res.data.data)
          )
        }
        setArr(newArr)
      }
    })
  }


  const delFn = (id) => {
    console.log(id)
    Modal.confirm({
      title: "删除产品确认",
      content: "您确认要删除该产品？",
      onOk() {
      DeleteProductByIdApi({ productId: id }
    ).then((res) => {
      // console.log(data)
      if (res.result) {
        message.success(res.msg)
        queryProductList()
      } else {
        message.error(res.msg)
      }
      })
      }
    })
  }

  //每一列
  const columns = [
    {
      title: '编号',
      dataIndex: 'id',
      key: 'id',
      width: 40,
      fixed: 'left',
    },
    {
      title: '产品名称',
      dataIndex: 'productName',
      key: 'productName',
      width: 120,
      fixed: 'left',
    },
    {
      title: '产品类型',
      dataIndex: 'productTypeDesc',
      key: 'productTypeDesc',
      width: 40,
    },
    {
      title: '产品描述',
      dataIndex: 'productDesc',
      key: 'productDesc',
      width: 120,
    },
    {
      title: '产品状态',
      dataIndex: 'productStatusDesc',
      key: 'productStatusDesc',
      width: 40,
    },
    {
      title: '产品地址',
      dataIndex: 'productUrl',
      key: 'productUrl',
      width: 100,
    },
    {
      title: '创建时间',
      dataIndex: 'createTime',
      key: 'createTime',
      width: 120,
      timestamp: true,
    },
    {
      title: '操作人',
      dataIndex: 'operatorName',
      key: 'operatorName',
      width: 40,
      timestamp: true,
    },
    {
      title: '操作',
      key: 'action',
      width: 100,
      render: (_, record) => (
        <Space size="middle">
          <Button type="primary" icon={<EditOutlined />} htmlType="submit" onClick={() => { setModifyProduct(true); setId(record.id); }}>
            编辑
          </Button>
          <Button type="primary" icon={<DeleteOutlined />} onClick={() => { delFn(record.id) }}>
            删除
          </Button>
          <Button type="primary" icon={<SearchOutlined />} onClick={() => { setQueryProduct(true); setId(record.id); }}>
            详情
          </Button>
          <Button type="primary" icon={<FileAddOutlined />} onClick={() => { setModifyPhoto(true); setId(record.id); }}>
            修改图像
          </Button>
        </Space>
      ),
    },
  ]

  return (
    <>
      <Form name="basic" layout="inline" onFinish={onFinish} autoComplete="off">
        <span>
          <Form.Item name="productName">
            <Input placeholder="请输入产品名称" />
          </Form.Item>
        </span>
        <span>
          <Form.Item>
            <Button type="primary" icon={<SearchOutlined />} htmlType="submit">
              查询
            </Button>
          </Form.Item>
        </span>
        <span style={{ textAlign: "right" }}>
          <Form.Item>
            <Button type="primary" icon={<PlusCircleOutlined />} htmlType="submit" onClick={() => setSaveProduct(true)}>
              新增
            </Button>
          </Form.Item>
        </span>
      </Form>

      <div className="list">
        <Table
          showHeader={true}
          columns={columns}
          dataSource={arr}
          scroll={{ x: 'max-content' }}
          rowKey="id"
        />
      </div>
      <AddPruduct open={saveProduct} handleCancel={() => setSaveProduct(false)} handleOk={(e) => { setSaveProduct(false); queryProductList() }} />
      <EditProduct id={id} open={modifyProduct} handleCancel={() => setModifyProduct(false)} handleOk={(e) => { setModifyProduct(false); queryProductList() }} />
      <ProductDetail id={id} open={queryProduct} handleCancel={() => setQueryProduct(false)} handleOk={(e) => { setQueryProduct(false); }} />
      <ModifyPhoto id={id} open={modifyPhoto} handleCancel={() => setModifyPhoto(false)} handleOk={(e) => { setModifyPhoto(false); }} />
    </>
  )
}
export default ProductList
