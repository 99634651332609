import React, {useState, useEffect, createContext} from 'react'
import '../../pages/less/List.less'
import {Table, Button, Form, Input, message, Modal, Space, Typography, TreeSelect, Cascader, Select, Radio} from 'antd'
import options from '../../components/AddressOptions'
import {QueryOrgList, AddOrg, QueryOrgById, UpdateOrg, UnMountOrgs, QueryOrgTreeAll} from './api'
import ComChildMountModel from './companyMount'
import DeleteButton from './components/unMountModal'
import {PlusCircleOutlined,} from '@ant-design/icons'
import 'moment/locale/zh-cn'

const {Link} = Typography;
const {Search} = Input;

const queryParams = {orderBy: 'update_time desc', categoriesIn: [2, 3, 4]}
const loginId = localStorage.getItem('id');

export const ComChildMountContext = createContext();

const convertDataToNewFormat = (data) => {
    return data.filter((item) => {
        const {orgType} = item;
        return orgType === 'comp' || orgType === 'temp';
    }).map(item => {
        const {id, orgName, children, orgType} = item;
        const newChildren = children ? convertDataToNewFormat(children) : null;
        return {
            value: `${orgName}-${id}`,
            label: orgName,
            children: newChildren
        };
    });
}


function AddCompany(props) {
    const [form] = Form.useForm();
    const [value, setValue] = useState();
    const [type, setType] = useState('comp');
    const [category, setCategory] = useState(2);
    const [lcoationCode, setLocationCode] = useState([]);
    const [locationName, setLocationName] = useState('');


    const treeData = convertDataToNewFormat(props.treeData)

    const onChangeTreeSelect = (newValue, label, extra) => {
        // console.log(newValue,label,extra);
        var choose = dataList.find((value, index, arr) => {
            let orgName = value.orgName
            let id = value.id
            return newValue === `${orgName}-${id}`
        });
        const {orgType,category, id} = choose
        setValue(id);
        setType(orgType)
        setCategory(category+1)
        form.setFieldsValue({
            category: category+1 ,
            orgType: orgType,
        })
    };

    const onChangeCascader = (newValue, selectedOptions) => {
        setLocationName('/' + selectedOptions.map((o) => o.label).join('/'))
        setLocationCode(newValue);
    };

    const handleOk = async () => {
        const e = await form.validateFields();
        let eLocation = e.location;
        let location = eLocation ? eLocation[eLocation.length - 1] : '';

        if (e.category === 3 && e.adminType === '2') {
            message.warning("已限制，不能创建房建项目")
            return;
        }
        AddOrg({
            orgParentId: value,
            locationCode: location,
            locationName: locationName,
            orgName: e.orgName,
            address: e.address,
            orgType: type,
            parentType: type,
            administratorName: e.administratorName,
            administratorMobile: e.administratorMobile,
            category: category,
            productType: e.adminType,
            type: e.type,
        }, {headers:
                {
                    'userCode': loginId,
                }
        }).then(function (response) {
            const {code, msg} = response;
            if (code === '200') {
                message.success("新增成功")
                props.handleOk(e);
            } else {
                message.error("新增失败：" + msg.replace("Service:org-management, ErrorMessage:", ""))
            }
        });


    }

    useEffect(() => {
        form.resetFields();
    }, [props.open, form])


    return (
        <Form form={form} validateTrigger={['onBlur', 'onChange']} labelAlign={'left'} autoComplete="off">
            <Modal title={'新建企业/项目'} width={480} open={props.open} onCancel={props.handleCancel} onOk={handleOk}>
                <Form.Item label='上级单位：' name='orgParentId' rules={[{required: true, message: '请选择上级单位'}]}>
                    <TreeSelect
                        style={{
                            width: '100%',
                        }}
                        value={value}
                        dropdownStyle={{
                            maxHeight: 400,
                            overflow: 'auto',
                        }}
                        showSearch={true}
                        // fieldNames={CascaderFieldNames}
                        treeData={treeData}
                        placeholder="请选择上级单位"
                        onChange={onChangeTreeSelect}
                    />
                </Form.Item>
                <Form.Item label='组织类型：' name={'orgType'} rules={[{required: true}]}>
                    <Radio.Group disabled>
                        <Radio value={'comp'}>职能型</Radio>
                        <Radio value={'temp'}>局指型</Radio>
                    </Radio.Group>
                </Form.Item>
                <Form.Item label='组织类别：' name={'category'} rules={[{required: true}]}>
                    <Radio.Group disabled>
                        <Radio value={2}>企业</Radio>
                        <Radio value={3}>项目</Radio>
                        <Radio value={4}>加工厂</Radio>
                    </Radio.Group>
                </Form.Item>
                {(category != undefined && category === 3) ?
                    <Form.Item label='项目类别：' name={'type'} rules={[{required: true}]}>
                        <Radio.Group>
                            <Radio value={0}>加工厂项目</Radio>
                            <Radio value={1}>梁厂项目</Radio>
                        </Radio.Group>
                    </Form.Item>
                    : ""}
                <Form.Item label='企业/项目名称：' name={'orgName'} rules={[{required: true, message: '请输入企业/项目名称名称'}]}>
                    <Input showCount maxLength={50}/>
                </Form.Item>
                <Form.Item label='省市区' name={'location'}>
                    <Cascader options={options} value={lcoationCode} onChange={onChangeCascader}/>
                </Form.Item>
                <Form.Item label='地址' name={'address'}>
                    <Input/>
                </Form.Item>
                <Form.Item label='管理员类型' name={'adminType'} rules={[{required: true, message: '请选择管理员类型'}]}>
                    <Select
                        style={{
                            width: 120,
                        }}
                        options={[
                            {
                                value: '1',
                                label: '基建',
                            },
                            {
                                value: '2',
                                label: '房建',
                            },
                        ]}
                    />
                </Form.Item>
                <Form.Item label='管理员姓名' name={'administratorName'} rules={[{required: true, message: '请输入管理员姓名'}]}>
                    <Input/>
                </Form.Item>
                <Form.Item label='管理员手机号' name={'administratorMobile'} rules={[{required: true, message: '请输入管理员手机号'}]}>
                    <Input/>
                </Form.Item>
            </Modal>
        </Form>
    )
}

function EditCompany(props) {
    const [category, setCategory] = useState(2);
    const [form] = Form.useForm();
    const treeData = convertDataToNewFormat(props.treeData)
    const onChangeCascader = (newValue, selectedOptions) => {
        let locationName = '/' + selectedOptions.map((o) => o.label).join('/')
        form.setFieldsValue({locationName})
    };

    const onChangeTreeSelect = (newValue, label, extra) => {
        let choose = dataList.find((value, index, arr) => {
            let orgName = value.orgName
            let id = value.id
            return newValue === `${orgName}-${id}`
        });
        const {orgType} = choose
        setCategory(choose.category + 1)
        form.setFieldsValue({orgType, category: category,})
    };

    const handleOk = async () => {
        const e = await form.validateFields();
        let locationArr = e.location;
        let location = locationArr[locationArr.length - 1];
        let choose = dataList.find((value, index, arr) => {
            let orgName = value.orgName
            let id = value.id
            return e.orgParentId === `${orgName}-${id}`
        });
        await UpdateOrg({
            id: props.id,
            locationCode: location,
            locationName: e.locationName,
            orgType: e.orgType, //和选择的父组织类型一致
            orgName: e.orgName,
            address: e.address,
            orgParentId: choose.id,
            parentType: choose.orgType,
            administratorName: e.administratorName,
            administratorMobile: e.administratorMobile,
            productType: e.adminType,
            category: e.category,
            type: e.type,
        }).then(function (res) {
            const {code, data, msg} = res;
            if (code === '200') {
                message.success("编辑成功")
                props.handleOk(e);
            } else {
                message.error("编辑失败:" + msg.replace("Service:org-management, ErrorMessage:", ""))
            }
        });
    }

    if (props.open === true && props.id) {
        QueryOrgById(props.id, props.type).then((res) => {
            const {code, data, msg} = res;
            if (code === '200') {
                const {
                    orgName,
                    locationCode,
                    address,
                    orgParentId,
                    locationName,
                    orgType,
                    administratorName,
                    administratorMobile,
                    productType,
                    category,
                    type
                } = data;
                let location = [];
                if (locationCode) {
                    let province = locationCode.substring(0, 2);
                    let matchCity = locationCode.substring(0, 4);
                    let areas = locationCode;
                    location = [province, matchCity, areas]
                }
                let orgParent = dataList.find((value, index, arr) => {
                    let id = value.id
                    return orgParentId === id
                });
                form.setFieldsValue({
                    orgName,
                    location: location,
                    orgParentId: `${orgParent.orgName}-${orgParent.id}`,
                    address,
                    locationName,
                    orgType,
                    category,
                    administratorName,
                    administratorMobile,
                    adminType: productType,
                    type: type
                })
                console.log("type", type)
            } else {
                message.error(msg)
            }
        })
    }

    useEffect(() => {
        form.resetFields();
    }, [props.open, form])


    return (
        <Form form={form} validateTrigger={['onBlur', 'onChange']} labelAlign={'left'} autoComplete="off">
            <Modal title={'编辑企业/项目'} width={480} open={props.open} onCancel={props.handleCancel} onOk={handleOk}>
                <Form.Item label='上级单位：' name='orgParentId' rules={[{required: true, message: '请选择上级单位'}]}>
                    <TreeSelect
                        style={{
                            width: '100%',
                        }}
                        dropdownStyle={{
                            maxHeight: 400,
                            overflow: 'auto',
                        }}
                        showSearch={true}
                        treeData={treeData}
                        placeholder="请选择上级单位"
                        onChange={onChangeTreeSelect}
                    />
                </Form.Item>
                <Form.Item label='组织类型：' name={'orgType'} rules={[{required: true}]}>
                    <Radio.Group disabled>
                        <Radio value={'comp'}>职能型</Radio>
                        <Radio value={'temp'}>局指型</Radio>
                    </Radio.Group>
                </Form.Item>
                <Form.Item label='组织类别：' name={'category'} rules={[{required: true}]}>
                    <Radio.Group disabled>
                        <Radio value={2}>企业</Radio>
                        <Radio value={3}>项目</Radio>
                        <Radio value={4}>加工厂</Radio>
                    </Radio.Group>
                </Form.Item>

                {(props.category != undefined && props.category === 3) ?
                    <Form.Item label='项目类别：' name={'type'} rules={[{required: true}]}>
                        <Radio.Group>
                            <Radio value={0}>加工厂项目</Radio>
                            <Radio value={1}>梁厂项目</Radio>
                        </Radio.Group>
                    </Form.Item>
                    : ""}


                <Form.Item label='企业/项目名称：' name={'orgName'} rules={[{required: true, message: '请输入企业/项目名称名称'}]}>
                    <Input showCount maxLength={50}/>
                </Form.Item>
                <Form.Item label='省市区名称：' hidden={true} name={'locationName'}>
                    <Input/>
                </Form.Item>
                <Form.Item label='省市区' name={'location'} rules={[{required: false, message: '请选择地址'}]}>
                    <Cascader options={options} onChange={onChangeCascader}/>
                </Form.Item>
                <Form.Item label='地址' name={'address'} rules={[{required: false, message: '请选择地址'}]}>
                    <Input/>
                </Form.Item>
                <Form.Item label='管理员类型' name={'adminType'} rules={[{required: false, message: '请选择管理员类型'}]}>
                    <Select
                        style={{
                            width: 120,
                        }}
                        disabled
                        options={[
                            {
                                value: '1',
                                label: '基建',
                            },
                            {
                                value: '2',
                                label: '房建',
                            },
                        ]}
                    />
                </Form.Item>
                <Form.Item label='管理员姓名' name={'administratorName'} rules={[{required: true, message: '请输入管理员姓名'}]}>
                    <Input/>
                </Form.Item>
                <Form.Item label='管理员手机号' name={'administratorMobile'} rules={[{required: true, message: '请输入管理员手机号'}]}>
                    <Input.Password/>
                </Form.Item>
            </Modal>
        </Form>
    )
}

const dataList = [];

const generateList = (data) => {
    for (let i = 0; i < data.length; i++) {
        const node = data[i];
        const {id, orgType, category, orgName} = node;
        dataList.push({
            id,
            orgType,
            category,
            orgName
        });
        if (node.children) {
            generateList(node.children);
        }
    }
};

const updateTreeData = (list, id, children) =>
    list.map((node) => {
        if (node.id === id) {
            return {
                ...node,
                children,
            };
        }
        if (node.children) {
            return {
                ...node,
                children: updateTreeData(node.children, id, children),
            };
        }
        return node;
    });


export default function CompanyList() {
    //公司项目列表
    const [arr, setArr] = useState({
        list: [],//公司项目列表
        count: 0 // 公司项目列表数量
    })
    // 分页参数
    const [pageParams, setPageParams] = useState({
        pageSize: 10,
        pageNum: 1
    })
    const [saveCompany, setSaveCompany] = useState(false);
    const [modifyCompany, setModifyCompany] = useState(false);
    const [id, setId] = useState();
    const [type, setType] = useState();
    const [category, setCategory] = useState();
    const [parentId, setParentId] = useState();
    const [isMountModalOpen, setIsMountModalOpen] = useState(false);
    const [visible, setVisible] = useState(false);
    const [accountingDate, setAccountingDate] = useState(31);
    const [selectNode, setSelectNode] = useState({});
    // 搜索
    const [search, setSearch] = useState('')

    //请求运营数据列表
    useEffect(() => {

        let params = {
            ...queryParams,
            like: search,
            // creatorId:loginId,
            page: pageParams,
        }
        QueryOrgList(params).then((res) => {
            const {code, data, msg} = res;
            if (code === '200') {
                if (data.list) {
                    const {list, total} = data
                    setArr({
                        list: list,
                        count: total
                    })
                }
            }
        })
    }, [pageParams])

    const onFinish = (value) => {
        setSearch(value ?? '')
        setPageParams({
            pageSize: 10,
            pageNum: 1
        })
    }

    // 翻页实现
    const pageChange = (page, pageSize) => {
        setPageParams({
            pageSize: pageSize,
            pageNum: page
        })
    }

    const showMountModal = (record) => {
        setSelectNode({
            id: record.id,
            orgName: record.orgName,
            orgType: record.orgType
        })
        setIsMountModalOpen(true)
    };

    const refreshChildList = () => {
        // setSearch('')
        setPageParams({
            pageSize: 10,
            pageNum: 1
        })
    }
    const unMount = (orgParentId, orgId) => {
        const params = {
            orgParentId: orgParentId,
            orgId: orgId
        }
        UnMountOrgs(params).then((res) => {
            const {code, msg} = res;
            if (code === '200') {
                message.success('卸载成功');
                // setSearch('')
                setPageParams({
                    pageSize: 10,
                    pageNum: 1
                })
            } else {
                message.error(msg);
            }
        })
    }

    // 下级组织列表
    const columns = [
        {title: 'ID', dataIndex: 'id', key: 'id', width: '10%',},
        {title: '客户经理', dataIndex: 'creatorName', key: 'creatorName', width: '8%',},
        {title: '企业/项目名称', dataIndex: 'path', key: 'path', width: '20%',},
        {
            title: '类型', dataIndex: 'orgType', key: 'orgType', width: '4%',
            render: (_, record) => {
                let orgType = record.orgType;
                let name = '';
                if (orgType === 'comp') {
                    name = '职能'
                } else if (orgType === 'temp') {
                    name = '局指'
                } else if (orgType === 'virt') {
                    name = '局指挂载'
                }
                return name;
            }
        },
        {
            title: '类别', dataIndex: 'category', key: 'category', width: '4%',
            render: (_, record) => {
                let category = record.category;
                let name = '';
                if (category === 1) {
                    name = '集团'
                } else if (category === 2) {
                    name = '企业'
                } else if (category === 3) {
                    name = '项目'
                } else if (category === 4) {
                    name = '加工厂'
                }
                return name;
            }
        },
        {
            title: '省市区地址', dataIndex: 'location', key: 'location', width: '20%',
            render: (_, record) => {
                let locationName = record.locationName || '';
                let adress = record.address || '';
                return locationName + "/" + adress;
            }
        },
        {title: '管理员姓名', dataIndex: 'administratorName', key: 'administratorName', width: '8%',},
        {title: '管理员手机号', dataIndex: 'administratorMobile', key: 'administratorMobile', width: '10%',},
        {title: '授权状态', dataIndex: 'authStatus', key: 'authStatus', width: '10%',},
        {
            title: '操作',
            key: 'action',
            width: '25%',
            render: (_, record) => {
                const paths = record.path.split("/")
                const pathStr = paths[paths.length - 2]
                return <Space size="middle">
                    {record.orgType === 'virt' ?
                        <DeleteButton onDelete={() => unMount(record.orgParentId, record.id)}
                                      modalContent={"删除后，该组织的数据无法汇总到" + pathStr}/>
                        : <Link onClick={() => {
                            setModifyCompany(true);
                            setId(record.id);
                            setParentId(record.orgParentId);
                            setType(record.orgType);
                            setCategory(record.category);
                            console.log("record.category ", record.category)
                        }}>
                            编辑
                        </Link>
                    }

                    {record.orgType === 'temp' ?
                        <Link onClick={() => {
                            showMountModal(record)
                        }}>
                            挂载
                        </Link>
                        : null}
                    {/* <Link >
                    更多
                  </Link> */}
                </Space>
            }
        },
    ];

    const addHandlerOk = (e) => {
        setSaveCompany(false);
        setPageParams({
            ...pageParams,
            pageNum: 1
        });
        onSyncLoadData();
    };

    const editHandlerOk = (e) => {
        setModifyCompany(false);
        setPageParams({
            ...pageParams,
            pageNum: 1
        })
        onSyncLoadData();
    };

    const [treeData, setTreeData] = useState([]);

    useEffect(() => {
        onSyncLoadData();
    }, [])

    const onSyncLoadData = async () => {
        QueryOrgTreeAll({'orgTypesIn': ['comp', 'temp']})
            .then(async (res) => {
                const {code, data, msg} = res;
                if (code === '200') {
                    setTreeData(data);
                    generateList(data);
                } else {
                    console.log(msg);
                }
            })
    }

    return (
        <>
            <Search
                style={{
                    width: 200,
                    marginRight: '10px'
                }}
                className='search'
                placeholder="企业/项目关键字"
                onSearch={onFinish}
                enterButton
            />
            <Button type="primary" icon={<PlusCircleOutlined/>} htmlType="submit" onClick={() => setSaveCompany(true)}>
                新增
            </Button>


            <div className="list">
                <Table
                    showHeader={true}
                    columns={columns}
                    dataSource={arr.list}
                    scroll={{x: 'max-content', y: 'calc(100vh - 400px)'}}
                    rowKey={(record, index) => record.path}
                    pagination={
                        {
                            showTotal: ((total) => {
                                return `共 ${total} 条`;
                            }),
                            pageSize: pageParams.pageSize,
                            total: arr.count,
                            onChange: pageChange,
                            current: pageParams.pageNum
                        }
                    }
                />
            </div>
            <AddCompany open={saveCompany} treeData={treeData} category={category} handleCancel={() => setSaveCompany(false)}
                        handleOk={addHandlerOk}/>
            <EditCompany id={id} type={type} parentId={parentId} open={modifyCompany} category={category}
                         treeData={treeData}
                         handleCancel={() => setModifyCompany(false)} handleOk={editHandlerOk}/>
            <ComChildMountContext.Provider value={{
                isMountModalOpen,
                setIsMountModalOpen,
                setVisible,
                setAccountingDate,
                onSyncLoadData,
                refreshChildList
            }}>
                {isMountModalOpen ? <ComChildMountModel selectNode={selectNode} treeData={treeData}/> : null}
            </ComChildMountContext.Provider>
            {visible ? <dev>
                <Modal
                    closable={false}
                    open={visible}
                    width={320}
                    onOk={() => setVisible(false)}
                    onCancel={() => setVisible(false)}
                    footer={[
                        <Button key="submit" type="primary" onClick={() => setVisible(false)}>
                            确认
                        </Button>,
                    ]}
                >
                    <p>企业的核算日期存在不一样情况，数据汇<br/>总将以最晚日期({accountingDate}日)为核算依据</p>
                </Modal>
            </dev> : null}
        </>
    )
}
