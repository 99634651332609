import { Input, Modal, Form, message, Space, Button} from 'antd';
import React, { useContext, useEffect } from 'react';
import {UpdateOrg} from './api'
import  { ChildUpdateContext } from './index'
const ChildUpdateModel = (props) => {
    let {
          isChildUpdateModalOpen, 
          setIsChildUpdateModalOpen, 
          selectChildNode,
          refreshChildList,
          onSyncLoadData,
        } = useContext(ChildUpdateContext);
    const [form] = Form.useForm();
    useEffect(() => {
      if (selectChildNode) {
        const {id, orgName, description,parentName} = selectChildNode;
        form.setFieldsValue({
            id:id,
            orgName: orgName,
            description :description,
            parentOrg:parentName,
        })
    }
    }, [])
    const handleOk = () => {
      setIsChildUpdateModalOpen(false);
    };
    
    const handleCancel = () => {
      setIsChildUpdateModalOpen(false);
    };

    const onFinish = async (values) => {
      const {orgType, orgParentId} = selectChildNode;
      const { id, orgName, description} = values
      const params = {
        id,
        orgType,
        orgName,
        description,
        orgParentId,
      }
      UpdateOrg(params).then( (res) => {
        const {code, data, msg} = res;
        if (code === '200') {
          message.success('组织编辑成功');
          onSyncLoadData();
           // 刷新下级组织列表
          refreshChildList(orgParentId, orgType);
          handleOk();
        } else {
          console.log(msg);
          message.error("组织编辑失败:" + msg.replace("Service:org-management, ErrorMessage:", ""))
        }
      })
     
    }
    return (
      <Modal title={'编辑下级组织'} width={480} open={isChildUpdateModalOpen} onCancel={handleCancel} footer={null}>
          <Form form={form} {...{labelCol: {span: 6},wrapperCol: {span: 16,}}}  
            validateTrigger={['onBlur','onChange']} labelAlign={'left'} autoComplete="off"
            onFinish={onFinish}>
              <Form.Item hidden={true} name={'id'}>
                    <Input />
                </Form.Item>
              <Form.Item  label='上级组织：' name='parentOrg' rules={[{ required:true, message:'请输入上级组织'}]}>
                <Input disabled/>
              </Form.Item>
              <Form.Item label='组织名称：' name={'orgName'}  rules={[{ required:true, message:'请输入组织名称'}]}>
                  <Input showCount maxLength={50}/>
              </Form.Item>
              <Form.Item label='组织描述：' name={'description'}  rules={[{ required:false, message:'请输入组织描述'}]}>
                  <Input />
              </Form.Item>
              <Form.Item wrapperCol={{ span: 9, offset: 9 }}>
                    <Space size={'large'}>
                        <Button htmlType="button" onClick={handleCancel}>
                            关闭
                        </Button>
                        <Button type="primary" htmlType="submit">
                            确认
                        </Button>
                    </Space>
                </Form.Item>
          </Form>
      </Modal>

    );
}

export default ChildUpdateModel;