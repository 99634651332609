import App from '../App'
import OperatorList from '../pages/auth/OperatorList'
import AppList from '../pages/auth/AppList'
import ProductList from '../pages/auth/ProductList'
import AuthList from '../pages/auth/AuthList'
import AuthDetail from '../pages/auth/AuthDetail'
import UserList from '../pages/auth/UserList'
import Org from '../pages/org/index'
import CompanyList from '../pages/org/companyList'
import Login from '../pages/Login'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'

//定义一个路由
const BaseRouter = () => (
  <Router>
    <Routes>
      <Route path="/login" element={<Login />}></Route>
      <Route path="/" element={<App />}>
        <Route path="/org" element={<Org />}></Route>
        <Route path="/operatorList" element={<OperatorList />}></Route>
        <Route path="/userList" element={<UserList />}></Route>
        <Route path="/appList" element={<AppList />}></Route>
        <Route path="/productList" element={<ProductList />}></Route>
        <Route path="/authList" element={<AuthList />}></Route>
        <Route path="/authDetail/:id" element={<AuthDetail />}></Route>
        <Route path="/comp" element={<CompanyList />}></Route>
      </Route>
    </Routes>
  </Router>
)
export default BaseRouter
