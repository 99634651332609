import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import '../../pages/less/List.less'
import { Table, Button, Form, Input, Modal, message, Space, DatePicker } from 'antd'
import { QueryAuthorityListApi, QueryAuthorityByIdApi, EditAuthorityApi, DeleteAuthorityApi } from '../../request/api'
import { EditOutlined, DeleteOutlined } from '@ant-design/icons'
import 'moment/locale/zh-cn'
import moment from 'moment';




const dataFormat = 'YYYY-MM-DD';


function EditAuth(props) {
  const { RangePicker } = DatePicker;
  const [form] = Form.useForm();
  const [authorityTypeNew, setAuthorityTypeNew] = useState()
  const handleOk = async () => {
    const e = await form.validateFields();
    const rangeValue = e['range-picker'];
    // console.log(e);
    const { result, msg } = await EditAuthorityApi({
      id: props.id,
      remark: e.remark,
      authorityNum: e.authorityNum,
      authorizeStartTime: rangeValue[0].format('YYYY-MM-DD'),
      authorizeEndTime: rangeValue[1].format('YYYY-MM-DD')
    });
    if (result) {
      message.success(msg)
      props.handleOk(e);
    } else {
      message.error(msg)
    }
  }

  const rangeConfig = {
    rules: [
      {
        type: 'array',
        required: true,
        message: '请选择授权时间!',
      },
    ],
  };

  if (props.id) {
    QueryAuthorityByIdApi({
      id: props.id
    }).then((res) => {
       console.log(res);
      if (res.result) {
        let data1 = res.data;
        const { remark, productId, productName, authorityType, authorityTypeDesc, authorityNum, authorizeStartTimeStr, authorizeEndTimeStr } = data1;
        setAuthorityTypeNew(authorityType)
        form.setFieldsValue({ remark, productId,productName, authorityType,authorityTypeDesc, authorityNum, "range-picker": [moment(authorizeStartTimeStr,dataFormat), moment(authorizeEndTimeStr,dataFormat)] })
      } else {
        message.error(res.msg)
      }
    })
  }

  useEffect(() => {
    form.resetFields();
  }, [props.open, form])

  return (
    <Form form={form} labelCol={{ span: 5 }} name="control-hooks" >
      <Modal title="编辑授权" open={props.open} onCancel={props.handleCancel} onOk={handleOk}>
        <Form.Item label="备注" name="remark" >
          <Input placeholder="请输入授权备注" />
        </Form.Item>
        <Form.Item label="产品名称" name="productName" >
          <Input readOnly />
        </Form.Item>
        <Form.Item label="授权模式" name="authorityTypeDesc">
         <Input readOnly />
        </Form.Item>
        <Form.Item label="项目数量" name="authorityNum" >
          <Input readOnly={authorityTypeNew==="1"? false: true} placeholder="请输入授权数量" />
        </Form.Item>
        <Form.Item name="range-picker" label="授权时间" {...rangeConfig}>
          <RangePicker />
        </Form.Item>
      </Modal>
    </Form >
  )
}

function AuthDetail() {
  const [editAuth, setEditAuth] = useState(false);
  const [id, setId] = useState();
  const params = useParams()
  //查询授权列表
  const [arr, setArr] = useState()
  let a = new URLSearchParams(window.location.search)
  // 根据地址栏id做请求
  //查询授权列表
  useEffect(() => {
    if (params.id) {
      QueryAuthorityListApi({
        orgId: params.id
      }).then((res) => {
        if (res.result) {
          let newArr = undefined
          if (res.data === null) {
            newArr = null
          } else {
            // console.log(res.data.data)
            newArr = JSON.parse(
              JSON.stringify(res.data.data)
            )
          }
          setArr(newArr)
          // console.log(newArr)
        }
      })
    }
  }, [params.id])

  const queryAuthList = () => {
    QueryAuthorityListApi({
      orgId: params.id
    }).then((res) => {
      if (res.result) {
        let newArr = undefined
        if (res.data === null) {
          newArr = null
        } else {
          // console.log(res.data.data)
          newArr = JSON.parse(
            JSON.stringify(res.data.data)
          )
        }
        setArr(newArr)
        // console.log(newArr)
      }
    })
  }

  const onFinish = (values) => {
    QueryAuthorityListApi({
      productName: values.productName
    }).then((res) => {
      if (res.result) {
        let newArr = undefined
        if (res.data === null) {
          newArr = null
        } else {
          newArr = JSON.parse(
            JSON.stringify(res.data.data)
          )
        }
        setArr(newArr)
        // console.log(newArr)
      }
    })
  }

  const delFn = (id) => {
    console.log(id)
    DeleteAuthorityApi({ authorityId: id }
    ).then((res) => {
      if (res.result) {
        message.success(res.msg)
        queryAuthList()
      } else {
        message.error(res.msg)
      }
    })
  }

  //每一列
  const columns = [
    {
      title: '操作人',
      dataIndex: 'operatorName',
      key: 'operatorName',
      width: 80,
      fixed: 'left',
    },
    {
      title: '充值时间',
      dataIndex: 'chargeTimeStr',
      key: 'chargeTimeStr',
      width: 80,
      fixed: 'left',
    },
    {
      title: '备注',
      dataIndex: 'remark',
      key: 'remark',
      width: 100,
    },
    {
      title: '产品名称',
      dataIndex: 'productName',
      key: 'productName',
      width: 100,
    },
    {
      title: '数量',
      dataIndex: 'authorityNum',
      key: 'authorityNum',
      width: 60,
    },
    {
      title: '状态',
      dataIndex: 'authorityStatusDesc',
      key: 'authorityStatusDesc',
      width: 60,
    },
    {
      title: '模式',
      dataIndex: 'authorityTypeDesc',
      key: 'authorityTypeDesc',
      width: 100,
    },
    {
      title: '开始时间',
      dataIndex: 'authorizeStartTimeStr',
      key: 'authorizeStartTimeStr',
      width: 60,
    },
    {
      title: '结束时间',
      dataIndex: 'authorizeEndTimeStr',
      key: 'authorizeEndTimeStr',
      width: 60,
    },
    {
      title: '操作',
      key: 'action',
      width: 100,
      render: (_, record) => (
        <Space size="middle">
          <Button type="primary" icon={<EditOutlined />} htmlType="submit" onClick={() => { setEditAuth(true); setId(record.id); }}>
            编辑授权
          </Button>
          {/* <Button type="primary"  icon={<DeleteOutlined />} onClick={() => { delFn(record.id) }}>
            删除授权
          </Button> */}
        </Space>
      ),
    },
  ]


  return (
    <>
      <Form name="basic" layout="inline" onFinish={onFinish} autoComplete="off">
        <Form.Item label="组织名称" name="orgName"  >
          <span>{a.get("orgName")}</span>
        </Form.Item>
      </Form>

      <div className="list">
        <Table
          showHeader={true}
          columns={columns}
          dataSource={arr}
          scroll={{ x: 'max-content' }}
          rowKey="id"
        />
      </div>
      <EditAuth id={id} open={editAuth} handleCancel={() => setEditAuth(false)} handleOk={(e) => { setEditAuth(false); queryAuthList() }} />
    </>
  )
}
export default AuthDetail
