import React, { useState, useEffect } from 'react'
import { HomeOutlined } from '@ant-design/icons'
import { Breadcrumb } from 'antd'
import { useLocation } from 'react-router-dom'

export default function Bread() {
  const { pathname } = useLocation()
  console.log(pathname)

  const [breadName, setBreadName] = useState('')

  //不是在组件mounted时去获取路径，而是路径变化就获取对应路径名称，并修改breadName
  //监听路由的路径
  useEffect(() => {
    switch (pathname) {
      case '/org':
        setBreadName('组织结构')
        break
      case '/operatorList':
        setBreadName('运营列表')
        break
      case '/appList':
        setBreadName('应用列表')
        break
      case '/productList':
        setBreadName('产品列表')
        break
      case '/userList':
        setBreadName('用户列表')
        break
      case '/authList':
        setBreadName('授权列表')
        break
      case '/comp':
        setBreadName('企业客户/项目')
        break
      default:
        setBreadName(pathname.includes('authDetail') ? '授权详情' : "");
        break
    }
  }, [pathname])

  return (
    <Breadcrumb style={{ height: '30px', lineHeight: '30px' }}>
      <Breadcrumb.Item href="/">
        <HomeOutlined />
      </Breadcrumb.Item>
      <Breadcrumb.Item>{breadName}</Breadcrumb.Item>
    </Breadcrumb>
  )
}
