import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import '../../pages/less/List.less'
import { Table, Button, Form, Input, Modal, message, Space, Select, DatePicker } from 'antd'
import { QueryOrgListApi, SaveAuthorityApi, QueryProductListApi } from '../../request/api'
import { SearchOutlined, PlusCircleOutlined } from '@ant-design/icons'
import 'moment/locale/zh-cn'


function AddAuth(props) {
  const { RangePicker } = DatePicker;
  const [form] = Form.useForm();
  const [dataSource, setDataSource] = useState([])
  const handleOk = async () => {
    const e = await form.validateFields();
    const rangeValue = e['range-picker'];
    console.log(e);
    // console.log("=====" + rangeValue);
    const { result, msg } = await SaveAuthorityApi({
      orgId: props.id,
      productId: e.productId,
      remark: e.remark,
      authorityNum: e.authorityNum,
      authorizeStartTime: rangeValue[0].format('YYYY-MM-DD'),
      authorizeEndTime: rangeValue[1].format('YYYY-MM-DD'),
      authorityType: e.authorityType
    });
    if (result) {
      message.success(msg)
      props.handleOk(e);
    } else {
      message.error(msg)
    }
  }

  useEffect(() => {
    form.resetFields();
  }, [props.open, form])


  const getProductList = () => {
    QueryProductListApi({
    }).then((res) => {
      // console.log(res)
      if (res.result) {
        let dataList = res.data.data;
        setDataSource(dataList)
      }
    })
  }
  const rangeConfig = {
    rules: [
      {
        type: 'array',
        required: true,
        message: '请选择授权时间!',
      },
    ],
  };

  return (
    <Form form={form} labelCol={{ span: 5 }} name="control-hooks" >
      <Modal title="新增授权" open={props.open} onCancel={props.handleCancel} onOk={handleOk}>
        <Form.Item label="组织名称" name="orgName"  >
          <span>{props.orgName}</span>
        </Form.Item>
        <Form.Item label="备注" name="remark" >
          <Input placeholder="请输入授权备注" />
        </Form.Item>
        <Form.Item label="产品" name="productId"  rules={[{ required: true, message: '请选择产品!' }]}>
          <Select placeholder="请选择" optionFilterProp="children"
            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            onFocus={getProductList}
          >
            {
              dataSource.map(field => (
                <Select.Option key={field.id} > {field.productName}</Select.Option>
              ))
            }
          </Select>
        </Form.Item>
        <Form.Item label="授权模式" name="authorityType"  rules={[{ required: true, message: '请选择产品授权模式' }]}>
          <Select placeholder="请选择">
            <Select.Option value="1">基于公司授权</Select.Option>
            <Select.Option value="2">基于项目授权</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item label="项目数量" name="authorityNum"  rules={[{ required: true, message: '请输入授权数量!' }]}>
          <Input placeholder="请输入授权数量" />
        </Form.Item>
        <Form.Item name="range-picker" label="授权时间" {...rangeConfig}>
          <RangePicker />
        </Form.Item>
      </Modal>
    </Form >
  )
}

function AuthList() {
  const navigate = useNavigate()
  const [saveAuth, setSaveAuth] = useState(false);
  const [id, setId] = useState();
  const [orgName, setOrgName] = useState();
  //查询组织列表
  const [arr, setArr] = useState({
    list: [],//组织列表
    count: 0 // 组织列表数量
  })
  const [count, setCount] = useState()
   // 分页参数
  const [pageParams, setPageParams] = useState({
    pageSize: 10,
    pageNum: 1
  })


  //查询组织列表
  useEffect(() => {
    QueryOrgListApi({
      categoriesIn: [2,3,4],
      orgType: 'comp',
      page:pageParams,
    }).then((res) => {
      // console.log(res)
      const {code, data, msg} = res;
      if (code==='200') {
        if (data.list) {
              const { list, total } = data
              setArr({
                list: list,
                count: total
              })
        }
      }
    })
  }, [pageParams])
  const onFinish = (values) => {
    QueryOrgListApi({
      like: values.orgName,
      categoriesIn: [2,3,4],
      orgType: 'comp',
      page:pageParams,
    }).then((res) => {
      const {code, data, msg} = res;
      if (code==='200') {
        if (data.list) {
              const { list, total } = data
              setArr({
                list: list,
                count: total
              })
        }
      }
    })
  }
  // 翻页实现
  const pageChange = (page) => {
    setPageParams({
      ...pageParams,
      pageNum:page
    })
  }

  //每一列
  const columns = [
    {
      title: '组织编码',
      dataIndex: 'id',
      key: 'id',
      width: 60,
      fixed: 'left',
    },
    {
      title: '组织名称',
      dataIndex: 'path',
      key: 'path',
      width: 200,
      fixed: 'left',
    },
    {
      title: '管理员姓名',
      dataIndex: 'administratorName',
      key: 'administratorName',
      width: 90,
    },
    {
      title: '操作',
      key: 'action',
      width: 100,
      render: (_, record) => (
        <Space size="middle">
          <Button type="primary" icon={<PlusCircleOutlined />} htmlType="submit" onClick={() => { setSaveAuth(true); setId(record.id); setOrgName(record.path); }}>
            新增授权
          </Button>
          <Button type="primary" icon={<SearchOutlined />} onClick={() => { navigate('/authDetail/' + record.id + '?orgName=' + record.path) }}>
            授权详情
          </Button>
        </Space>
      ),
    },
  ]

  return (
    <>
      <Form name="basic" layout="inline" onFinish={onFinish} autoComplete="off">
        <span>
          <Form.Item name="orgName">
            <Input placeholder="请输入组织名称" />
          </Form.Item>
        </span>
        <span>
          <Form.Item>
            <Button type="primary" icon={<SearchOutlined />} htmlType="submit">
              查询
            </Button>
          </Form.Item>
        </span>
      </Form>

      <div className="list">
        <Table
          showHeader={true}
          columns={columns}
          dataSource={arr.list}
          scroll={{ x: 'max-content' }}
          rowKey="id"
          pagination={
            {
              showTotal:((total) => {
                return `共 ${total} 条`;
              }),
              pageSize: pageParams.pageSize,
              total: arr.count,
              onChange: pageChange,
              current: pageParams.pageNum
            }
          }
        />
      </div>
      <AddAuth id={id} orgName={orgName} open={saveAuth} handleCancel={() => setSaveAuth(false)} handleOk={(e) => { setSaveAuth(false); }} />
    </>
  )
}
export default AuthList
