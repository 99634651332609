import axios from 'axios'
import ConfigPath from '../ConfigPath';

// 配置项
const axiosOption = {
  // baseURL: 'https://pandora-api.rmes.cn/',
  baseURL: ConfigPath.baseURL,
  timeout: 5000
}

// 创建一个单例
const instance = axios.create(axiosOption);

// 添加请求拦截器
instance.interceptors.request.use(function (config) {
  let userToken = localStorage.getItem('userToken')
  // let userType = localStorage.getItem('userType')
  // let id = localStorage.getItem('id')
  if (userToken) {
    config.headers = {
      'userToken': userToken,
      // 'userType': userType,
      // 'userCode': id
    }
  }
  return config;
}, function (error) {
  // 对请求错误做些什么
  return Promise.reject(error);
});

// 添加响应拦截器
instance.interceptors.response.use(function (response) {
  //登录token失效跳转登录页面
  if (response.data.code ==='UCC0000015') { 
     window.location.href = "/login"
  }
  return response.data;
}, function (error) {
  // 对响应错误做点什么
  return Promise.reject(error);
});

export default instance;
