import React from 'react'
import zhCN from 'antd/lib/locale/zh_CN';
import { Layout, ConfigProvider } from 'antd'
import { Outlet } from 'react-router-dom'
import Header from './components/Header'
import Aside from './components/Aside'
import Bread from './components/Bread'

export default function App() {
  const token = window.localStorage.getItem("userToken")
  if (!token) {
    window.location.href = "/login"
  }
  return (
    <ConfigProvider locale={zhCN}>
      <Layout id="app">
        <Header />
        <div className="container">
          <Aside />
          <div className="container_box">
            <Bread />
            <div className="container_content">
              <Outlet />
            </div>
          </div>
        </div>
        <footer>北京迈思发展科技有限责任公司 Copyright 2022</footer>
      </Layout>
    </ConfigProvider>

  )
}
