import request from './request'
import ConfigPath from '../ConfigPath';

const baseAuthUrl = ConfigPath.baseAuthUrl;
const baseOrgUrl = ConfigPath.baseOrgUrl;

//登陆
export const LoginApi = (params) => request.post(baseAuthUrl + '/login/login', params)

//登出
export const LogoutApi = () => request.post(baseAuthUrl + '/login/logout')


//查询运营端用户列表
export const QueryOperatorListApi = (params) => request.post(baseAuthUrl + '/operator/queryOperatorList', params)



//查询应用列表
export const QueryAppListApi = (params) => request.post(baseAuthUrl + '/app/queryAppList', params)


//新增产品
export const SaveProductApi = (params) => request.post(baseAuthUrl + '/product/saveProduct', params)


//查询产品列表
export const QueryProductListApi = (params) => request.post(baseAuthUrl + '/product/queryProductList', params)


//编辑产品
export const EditProductApi = (params) => request.post(baseAuthUrl + '/product/editProduct', params)


//根据产品id查询产品信息
export const QueryProductByIdApi = (params) => request.get(baseAuthUrl + `/product/queryProductById/${params.id}`)


//删除产品
export const DeleteProductByIdApi = (params) => request.post(baseAuthUrl + '/product/deleteProductById', params)


//查询授权列表
export const QueryAuthorityListApi = (params) => request.post(baseAuthUrl + '/authority/queryAuthorityList', params)


//查询组织列表
export const QueryOrgListApi = (params) => request.post(baseOrgUrl + '/query', params)


//新增授权
export const SaveAuthorityApi = (params) => request.post(baseAuthUrl + '/authority/saveAuthority', params)

//根据产品id查询产品信息
export const QueryAuthorityByIdApi = (params) => request.get(baseAuthUrl + `/authority/queryAuthorityById/${params.id}`)

//编辑授权
export const EditAuthorityApi = (params) => request.post(baseAuthUrl + '/authority/editAuthority', params)

//删除授权
export const DeleteAuthorityApi = (params) => request.post(baseAuthUrl + '/authority/deleteAuthority', params)


//条件查询用户列表
export const QueryUserListApi = (params) => request.post(baseAuthUrl + '/user/queryUserList', params)

//解锁用户
export const UnlockUserByIdApi = (params) => request.post(baseAuthUrl + '/user/unlockUserById', params)

//注销用户
export const DeleteUserApi = (params) => request.post(baseAuthUrl + '/user/deleteUser', params)

// 获取短信验证码
export const GetSmsApi = (params) => request.post(baseAuthUrl + '/sms/captcha/get', params)

//修改客户密码
export const UpdatePasswordApi = (params) => request.post(baseAuthUrl + '/operator/update/password', params)




