import {
  GoldTwoTone,
  DownSquareTwoTone,
} from '@ant-design/icons';
import { Input, Tree, Row, Col, message, Button, Select, Typography, Divider, Space, Popconfirm, Table, Modal } from 'antd';
import React, { useState, useEffect, createContext } from 'react';
import { QueryOrgTree, DeleteOrg, QueryOrgTreeAll, UnMountOrgs } from './api'
import RootCreateModel from './rootCreate'
import ChildCreateModel from './childCreate'
import RootUpdateModel from './rootUpdate'
import ChildUpdateModel from './childUpdate'
import ChildMountModel from './childMount'
import DeleteButton from './components/unMountModal'
import './index.css'

const { Link, Title } = Typography;
const { Search } = Input;
const { Option } = Select;
const rootOrgParentId = 0;

export const RootContext = createContext();
export const RootUpdateContext = createContext();
export const ChildContext = createContext();
export const ChildMountContext = createContext();
export const ChildUpdateContext = createContext();

const dataList = [];

const treeFieldNames = { title: 'orgName', key: 'idAndType',}

const generateList = (data) => {
  for (let i = 0; i < data.length; i++) {
    const node = data[i];
    const { id, orgName, children, orgParentId, description, orgType, idAndType } = node;
    dataList.push({
      id,
      orgName,
      children,
      description,
      orgParentId,
      orgType,
      idAndType
    });
    if (node.children) {
      generateList(node.children);
    }
  }
};

const getParentKey = (key, tree) => {
  let parentKey;
  for (let i = 0; i < tree.length; i++) {
    const node = tree[i];
    if (node.children) {
      if (node.children.some((item) => item.id === key)) {
        parentKey = node.idAndType;
      } else if (getParentKey(key, node.children)) {
        parentKey = getParentKey(key, node.children);
      }
    }
  }
  return parentKey;
};

const updateTreeData = (list, key, children) =>
  list.map((node) => {
    if (node.idAndType === key) {
      return {
        ...node,
        children,
      };
    }
    if (node.children) {
      return {
        ...node,
        children: updateTreeData(node.children, key, children),
      };
    }
    return node;
  });


const Organization = () => {
  const [treeData, setTreeData] = useState([]);
  const [showOrgName, setShowOrgName] = useState('');
  const [childrenList, setChildrenList] = useState([]);
  const [expandedKeys, setExpandedKeys] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [autoExpandParent, setAutoExpandParent] = useState(true);
  const [selectNode, setSelectNode] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [selectChildNode, setSelectChildNode] = useState({});
  const [isChildModalOpen, setIsChildModalOpen] = useState(false);
  const [isMountModalOpen, setIsMountModalOpen] = useState(false);
  const [isChildUpdateModalOpen, setIsChildUpdateModalOpen] = useState(false);
  const [visible, setVisible] = useState(false); 
  const [accountingDate, setAccountingDate] = useState(31);  


  //加载tree组件，获取root组织列表数据
  useEffect(() => {
    onSyncLoadData();
  }, [])

  // 展开树节点
  const onExpand = (newExpandedKeys) => {
    setExpandedKeys(newExpandedKeys);
    setAutoExpandParent(false);
  };

  const setIdAndType= (node) => {
    node.map((item)=> {
      let idAndType = item.id + '-' + item.orgType
      item.idAndType = idAndType;
      if (item.children) {
        setIdAndType(item.children)
      }
      return item;
    })
    
  }

  const onSyncLoadData = async () => {
    dataList.length = 0;
    const { id,orgType } = selectNode;
    QueryOrgTreeAll({orgTypesIn:['comp','temp']})
      .then(async (res) => {
        const {code, data, msg} = res;
        if (code === '200') {
          setIdAndType(data)
          setTreeData(data);
          generateList(data);
          if (id) {
            onSelect([id + '-' + orgType]);
          }
          setSelectedKeys([]);
        }
      })
  }

  const syncLoadChildData = async (key, type) => {
    const response = await QueryOrgTree(key, type)
    const {code, data, msg} = response;
    if (code === '200') {
      return data
    }
  }

  // tree节点选中事件  
  const onSelect = async (selectedKeys) => {
    if (selectedKeys.length === 0) {
      return;
    }
    let select = dataList.find(function (data) {
      return data.idAndType === selectedKeys[0]
    })
        let id = select ? select.id || null : null;
        let orgName = select ? select.orgName || '' : '';
        let orgType = select ? select.orgType || '' : '';
        let children = select ? select.children || '' : '';
        let hasChild = select ? (select.children ? select.children.length > 0 : false) : false;
        let parentId = select ? select.orgParentId || rootOrgParentId : rootOrgParentId;
        let description = select ? select.description : null;
        setShowOrgName(orgName);
        setChildrenList(children);
        setSelectNode({
          id: id || null,
          orgName: orgName,
          orgType:orgType,
          parentId: parentId,
          description: description,
          hasChild: hasChild,
        })
  }

  const onChange = (value) => {
    const newExpandedKeys = dataList.map((item) => {
      if (item.orgName.indexOf(value) > -1) {
        return getParentKey(item.id, treeData);
      }
      return null;
    })
      .filter((item, i, self) => item && self.indexOf(item) === i);
    setExpandedKeys(newExpandedKeys);
    setSearchValue(value);
    setAutoExpandParent(true);
  };

  const showMountModal = () => {
    setIsMountModalOpen(true)
  };

  const showChildModal = () => {
    if (showOrgName.length === 0) {
      message.info("请先选择一级组织")
      return;
    }
    setIsChildModalOpen(true);
  };

  const updateRootOrg = () => {
    if (showOrgName.length === 0) {
      message.info("请先选择一级组织")
      return;
    }
    setIsUpdateModalOpen(true);
  }
  const deleteOrg = async () => {
    if (showOrgName.length === 0) {
      message.info("请先选择一级组织")
      return;
    }
    const { id, hasChild } = selectNode;
    if (hasChild) {
      message.error('禁止删除有下级组织的组织！');
    } else {
      await DeleteOrg(id).then((res) => {
        message.success('删除组织成功');
        onSyncLoadData();
      })
    }
  }

  const refreshChildList = async (rootKey, type) => {
    await QueryOrgTree(rootKey,type).then((res) => {
      const {code, data, msg} = res;
      if (code === '200') {
        setChildrenList(data);
      } else {
        setChildrenList([]);
      }
    })
  }


  // 下级组织列表
  const columns = [
    { title: '组织ID', dataIndex: 'id', key: 'key', width: '12%', },
    { title: '组织名称', dataIndex: 'orgName', key: 'title', width: '15%', },
    { title: '组织全路径', dataIndex: 'path', key: 'path', width: '30%', },
    { title: '创建时间', dataIndex: 'createTime', width: '10%', key: 'createTime', },
    { title: '描述', dataIndex: 'description', width: '20%', key: 'description', },
    {
      title: '操作',
      key: 'action',
      width: '25%',
      render: (_, record) => 
          {

              return (<Space size="middle">
                <Link onClick={() => editChildOrg(record)}>
                  编辑
                </Link>
                {record.orgType === 'virt' 
                ? 
                <DeleteButton onDelete={() => unMountChildOrg(record.orgParentId, record.id)} modalContent={"删除后，该组织的数据无法汇总到"+ showOrgName} />
                :
                <Popconfirm title="确定删除?" onConfirm={() => deleteChildOrg(record.id)}>
                  <a>删除</a>
                </Popconfirm>
                }
              </Space>);
          }
    },
  ];

  const editChildOrg = async (item) => {
    setSelectChildNode({
      ...item,
      // orgType: baseOrgType,
      parentName: showOrgName,
    });
    setIsChildUpdateModalOpen(true);
  }

  const deleteChildOrg = (key) => {
    DeleteOrg(key).then((res) => {
      const {code, msg} = res;
      if (code === '200') {
        message.success('删除组织成功');
        onSyncLoadData();
        setChildrenList(childrenList.filter((item) => item.id !== key));
      } else {
        message.error(msg);
      }
    })
  }

  const unMountChildOrg = (orgParentId, orgId) => {
    const params = {
      orgParentId:orgParentId,
      orgId:orgId
  }
   UnMountOrgs(params).then((res) => {
    const {code, msg} = res;
    if (code === '200') {
      message.success('卸载成功');
      onSyncLoadData();
      setChildrenList(childrenList.filter((item) => item.id !== orgId));
    } else {
      message.error(msg);
    }
  })
  }

  const { orgType,} = selectNode;
  return (
    <>
      <div className="Organization">
        <div className="left-tree">
          <Search
            className='search'
            placeholder="组织查询"
            onSearch={onChange}
            enterButton
          />
          <Button className='button' type="dashed" block onClick={() => setIsModalOpen(true)}>新增一级组织</Button>
          <Tree
            style={{ overflowY: 'auto',maxHeight:'calc(100vh - 300px)' }}
            onExpand={onExpand}
            fieldNames={treeFieldNames}
            expandedKeys={expandedKeys}
            selectedKeys={selectedKeys}
            onSelect={onSelect}
            autoExpandParent={autoExpandParent}
            treeData={treeData}
            titleRender={(node)=>{
              const {orgName, orgType} = node
              return (
              <span className="titleRender">
                 <span className="titleRight">
                     {orgType === 'temp' ? <span style={{color:'blue'}}>{orgName}</span> :<span>{orgName}</span>}
                 </span>
              </span>
           )}
          }
          />
        </div>

        <div className="right-con">
          <Row className="root-org">
            <GoldTwoTone style={{ fontSize: '250%', marginLeft: '10px' }} />
            <Col style={{ marginLeft: '5px' }}>
              <Title level={3} >{showOrgName}</Title>
            </Col>
            {orgType === 'virt' ? null :
            <>
              <Button style={{ marginLeft: '15px' }} onClick={updateRootOrg} type="primary">编辑</Button>
              <Popconfirm title="确定删除?" onConfirm={deleteOrg}>
                <Button style={{ marginLeft: '5px' }} type="primary" danger>删除</Button>
              </Popconfirm>
            </> 
            }

            
          </Row>
          <Divider />
          <Row className="root-org">
            <DownSquareTwoTone style={{ fontSize: '200%', marginLeft: '10px' }} />
            <Col style={{ marginLeft: '5px' }}>
              <Title level={4}  >下级组织</Title>
            </Col>
            {orgType === 'comp' ? 
            <>
              <Button style={{ marginLeft: '15px' }} type="primary" onClick={showChildModal}>新增</Button>
            </> 
            :null}
            {orgType === 'temp' ? <Button style={{ marginLeft: '5px' }} type="primary" onClick={showMountModal}>挂载</Button> : null}
          </Row>
          <Table columns={columns} dataSource={childrenList} scroll={{ y: 'calc(100vh - 500px)' }} />
        </div>
      </div>
      
      <RootContext.Provider value={{ isModalOpen, setIsModalOpen, onSyncLoadData }}>
        {isModalOpen ? <RootCreateModel /> : null}
      </RootContext.Provider>

      <RootUpdateContext.Provider value={{ isUpdateModalOpen, setIsUpdateModalOpen, selectNode, onSyncLoadData }}>
        {isUpdateModalOpen ? <RootUpdateModel /> : null}
      </RootUpdateContext.Provider>

      <ChildContext.Provider value={{ isChildModalOpen, setIsChildModalOpen, onSyncLoadData, refreshChildList }}>
        {isChildModalOpen ? <ChildCreateModel selectNode={selectNode} /> : null}
      </ChildContext.Provider>

      <ChildUpdateContext.Provider value={{ isChildUpdateModalOpen, setIsChildUpdateModalOpen, selectChildNode, onSyncLoadData, refreshChildList }}>
        {isChildUpdateModalOpen ? <ChildUpdateModel /> : null}
      </ChildUpdateContext.Provider>

      <ChildMountContext.Provider value={{ isMountModalOpen, setIsMountModalOpen, setVisible, setAccountingDate, onSyncLoadData, refreshChildList }}>
        {isMountModalOpen ? <ChildMountModel selectNode={selectNode} treeData = {treeData} /> : null}
      </ChildMountContext.Provider>
      {visible ? <dev>
      <Modal   
          closable={false}
          open={visible}  
          width={320}
          onOk={() => setVisible(false)}  
          onCancel={() => setVisible(false)}  
          footer={[  
            <Button key="submit" type="primary" onClick={() => setVisible(false)}>  
              确认  
            </Button>,  
          ]}  
        >  
          <p>企业的核算日期存在不一样情况，数据汇<br/>总将以最晚日期({accountingDate}日)为核算依据</p>  
        </Modal>
      </dev> : null}

    </>
  )

}
export default Organization