import { Input, Modal, Form, message, Space, Button, Radio} from 'antd';
import React, { useContext, useEffect,useState} from 'react';
import {BatchAddOrgs} from './api'
import  { ChildContext } from './index'
const { TextArea } = Input;

const loginId = localStorage.getItem('id');

const ChildCreateModel = (props) => {
    let {isChildModalOpen, setIsChildModalOpen, refreshChildList, onSyncLoadData} = useContext(ChildContext);
    const {id, orgName, orgType} = props.selectNode;
    const [radioValue, setRadioValue] = useState('comp');
    const [form] = Form.useForm();
    useEffect(() => {
      form.setFieldsValue({parentOrg: orgName, type:radioValue})
    }, [])

    
    const onChange = (e) => {
      setRadioValue(e.target.value)
      console.log(e.target.value)
      return  e.target.value;
    };

    const handleOk = () => {
      setIsChildModalOpen(false);
    };
    
    const handleCancel = () => {
      setIsChildModalOpen(false);
    };

    const onFinish = async (values) => {
      // 数据的二次处理
      const { orgName, description} = values
      var orgNameArray = orgName.split(/[(\r\n)\r\n]+/);
      orgNameArray = orgNameArray.filter(item => item)
      const isInvalid = orgNameArray.some(line => line.length > 50);

      if (isInvalid) {
        message.error('组织名称长度不能超过50');
        return
      }
      const params = {
          parentId:id,
          parentType:orgType,
          orgType:radioValue,
          childOrgNames:orgNameArray,
          description,
          category:1,
      }
       await BatchAddOrgs(params, {
        headers:{
          'userCode': loginId,
        }
      }).then(function (response) {
        const {code, data, msg} = response;
          if (code === '200') {
            message.success('下级组织新增成功');
           // 刷新下级组织列表
           onSyncLoadData();
           refreshChildList(id, orgType);
           handleOk();
          } else {
            message.error('新增失败:' + msg.replace("Service:org-management, ErrorMessage:", ""));
            console.log(msg);
          }
        });
    }

    return (
      <Modal title={'新增下级组织'} width={480} open={isChildModalOpen} onCancel={handleCancel} footer={null}>
          <Form form={form} {...{labelCol: {span: 6},wrapperCol: {span: 16,}}}  
            validateTrigger={['onBlur','onChange']} labelAlign={'left'} autoComplete="off"
            onFinish={onFinish}>
              <Form.Item  label='上级组织：' name='parentOrg' rules={[{ required:true, message:'请输入上级组织'}]}>
                <Input disabled/>
              </Form.Item>
              <Form.Item label='组织类型：' name={'type'} rules={[{ required:true}]}>
                    <Radio.Group onChange={onChange} value={radioValue}>
                        <Radio value={'comp'}>职能型</Radio>
                        <Radio value={'temp'}>局指型</Radio>
                    </Radio.Group>
                </Form.Item>
              <Form.Item label='组织名称：' name={'orgName'}  rules={[{ required:true, message:'请输入组织名称'}]}>
                  <TextArea rows={4} allowClear={true}/>
              </Form.Item>
              <Form.Item label='组织描述：' name={'description'}  rules={[{ required:false, message:'请输入组织描述'}]}>
                  <Input />
              </Form.Item>
              <Form.Item wrapperCol={{ span: 9, offset: 9 }}>
                    <Space size={'large'}>
                        <Button htmlType="button" onClick={handleCancel}>
                            关闭
                        </Button>
                        <Button type="primary" htmlType="submit">
                            确认
                        </Button>
                    </Space>
                </Form.Item>
          </Form>
      </Modal>

    );
}

export default ChildCreateModel;