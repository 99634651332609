import React, { useState, useEffect } from 'react'
import '../../pages/less/List.less'
import { Table, Button, Form, Input } from 'antd'
import { QueryOperatorListApi } from '../../request/api'
import { SearchOutlined } from '@ant-design/icons'
import 'moment/locale/zh-cn'


export default function OperatorList() {
  //运营数据列表数组
  const [arr, setArr] = useState()

  //请求运营数据列表
  useEffect(() => {
    QueryOperatorListApi({
    }).then((res) => {
      if (res.result) {
        let newArr = undefined
        if (res.data === null) {
          newArr = null
        } else {
          newArr = JSON.parse(
            JSON.stringify(res.data.data)
          )
        }
        setArr(newArr)
      }
    })
  }, [])
  const onFinish = (values) => {
    QueryOperatorListApi({
      userName: values.userName
    }).then((res) => {
      console.log(res)
      if (res.result) {
        let newArr = undefined
        if (res.data === null) {
          newArr = null
        } else {
          newArr = JSON.parse(
            JSON.stringify(res.data.data)
          )
        }
        setArr(newArr)
      }
    })
  }

  //每一列
  const columns = [
    {
      title: '编号',
      dataIndex: 'id',
      key: 'id',
      width: 60,
      fixed: 'left',
    },
    {
      title: '账号',
      dataIndex: 'userName',
      key: 'userName',
      width: 90,
      fixed: 'left',
    },
    {
      title: '姓名',
      dataIndex: 'realName',
      key: 'realName',
      width: 90,
    },
    {
      title: '手机号码',
      dataIndex: 'mobile',
      key: 'mobile',
      width: 90,
    },
    {
      title: '邮箱',
      dataIndex: 'email',
      key: 'email',
      width: 90,
    },
    {
      title: '状态',
      dataIndex: 'userStatusDesc',
      key: 'userStatusDesc',
      width: 100,
    },
    {
      title: '注册时间',
      dataIndex: 'registerTime',
      key: 'registerTimeregisterTime',
      width: 105,
    }
  ]

  return (
    <>
      <Form name="basic" layout="inline" onFinish={onFinish} autoComplete="off">
        <Form.Item name="userName">
          <Input placeholder="请输入用户账号" />
        </Form.Item>
        <Form.Item>
          <Button type="primary" icon={<SearchOutlined />} htmlType="submit">
            查询
          </Button>
        </Form.Item>
      </Form>

      <div className="list">
        <Table
          showHeader={true}
          columns={columns}
          dataSource={arr}
          scroll={{ x: 'max-content' }}
          rowKey="id"
        />
      </div>
    </>
  )
}
