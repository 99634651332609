import request from '../../request/request'
import ConfigPath from '../../ConfigPath';

// const baseUrl = ConfigPath.baseOrgUrlLocal;
const baseUrl = ConfigPath.baseOrgUrl;

export const orgType = 'comp';

//创建组织
export const AddOrg = (params, config) => request.put(baseUrl, params, config);

//批量创建组织
export const BatchAddOrgs = (params, config) => request.put(baseUrl + '/batch', params, config);

//更新组织
export const UpdateOrg = (params, config) => request.patch(baseUrl, params, config);

//根据id删除组织
export const DeleteOrg = (id) => request.delete(baseUrl + '/' + id);

//根据组织ID和类型查询组织信息
export const QueryOrgById = (id, type, config) => request.get(baseUrl + '/' + id + '?type=' + type, { config });

//条件查询组织列表
export const QueryOrgList = (params) => request.post(baseUrl + '/query', params);

//查询顶级组织列表
export const QueryRootOrgList = (type, config) => request.get(baseUrl + '/root/query?type=' + type, { config });

//查询某个组织直属下级组织列表
export const QueryChildOrgList = (id, config) => request.get(baseUrl + '/child/' + id, { config });

//查询组织tree
export const QueryOrgTree = (id, type, config) => request.get(baseUrl + '/tree/' + id + '?type=' + type, { config });

//tree
export const QueryOrgTreeAll = (params, config) => request.post(baseUrl + '/tree/query', params, config);

//挂载下级组织
export const MountOrgs = (params) => request.post(baseUrl + '/mount', params);

//卸载下级组织
export const UnMountOrgs = (params) => request.post(baseUrl + '/unmount', params);

//校验局指下项目的父职能组织核算天数是否一致
export const checkAccountingDate = (id) => request.post(baseUrl + '/check/accountingDate/' + id);







