import React, { useState, useEffect } from 'react'
import '../../pages/less/List.less'
import { Table, Button, Form, Input, message, Space,Modal } from 'antd'
import { QueryUserListApi, UnlockUserByIdApi, DeleteUserApi } from '../../request/api'
import { SearchOutlined, UserDeleteOutlined, UnlockOutlined } from '@ant-design/icons'
import 'moment/locale/zh-cn'

function UserList() {

  //查询用户列表
  const [arr, setArr] = useState({
    list: [],//用户列表
    count: 0 // 用户数量
  })
  // 分页参数
  const [pageParams, setPageParams] = useState({
    pageSize: 10,
    pageNum: 1
  })
  // 搜索
  const [search, setSearch] = useState('')


  //查询用户列表
  useEffect(() => {
    let params = {
      pageConfig:pageParams,
      realName: search,
    }
    QueryUserListApi(params).then((res) => {
      if (res.result) {
        let newArr = undefined
        let newArrCount = 0
        if (res.data === null) {
          newArr = null
        } else {
          newArr = JSON.parse(
            JSON.stringify(res.data.data)
          )
          newArrCount = res.data.total ?? 0
        }
        setArr({
          list: newArr,
          count: newArrCount
        })
      }
    })
  }, [pageParams])

  const onFinish = (values) => {
    setSearch(values?.userName ?? '')
    setPageParams({
      pageSize: 10,
      pageNum: 1
    })
  }

  const reset = () => {
    setPageParams({
      pageSize: 10,
      pageNum: 1
    })
  }
  
  //注销用户
  const delFn = (id) => {
    Modal.confirm({
      title: "注销用户确认",
      content: "您确认要注销该用户？",
      onOk() {
         DeleteUserApi({ userId: id }
    ).then((res) => {
      if (res.result) {
        message.success(res.data)
        reset()
      } else {
        message.error(res.msg)
      }
    })
      }
    });
  }

  //解锁用户
  const unlockFn = (id) => {
    console.log(id)
    UnlockUserByIdApi({ userId: id }
    ).then((res) => {
      // let data = res.body;
      // console.log(data)
      if (res.result) {
        message.success(res.data)
        reset()
      } else {
        message.error(res.msg)
      }
    })
  }
  
// 翻页实现
const pageChange = (page, pageSize) => {
  setPageParams({
    pageSize:pageSize,
    pageNum:page
  })
}

  //每一列
  const columns = [
    {
      title: '用户id',
      dataIndex: 'id',
      key: 'id',
      width: 60,
      fixed: 'left',
    },
    {
      title: '用户账号',
      dataIndex: 'userName',
      key: 'userName',
      width: 60,
      fixed: 'left',
    },
    {
      title: '用户姓名',
      dataIndex: 'realName',
      key: 'realName',
      width: 90,
    },
    {
      title: '手机号码',
      dataIndex: 'mobile',
      key: 'mobile',
      width: 90,
    },
    {
      title: '邮箱',
      dataIndex: 'email',
      key: 'email',
      width: 90,
    },
    {
      title: '用户状态',
      dataIndex: 'userStatusDesc',
      key: 'userStatusDesc',
      width: 90,
    },
    {
      title: '注册时间',
      dataIndex: 'registerTime',
      key: 'registerTime',
      width: 120,
    },
    {
      title: '操作',
      key: 'action',
      width: 100,
      render: (_, record) => (
        <Space size="middle">
          <Button type="primary" icon={<UnlockOutlined />} htmlType="submit" onClick={() => { unlockFn(record.id) }}>
            解锁用户
          </Button>
          <Button type="primary" icon={<UserDeleteOutlined />} onClick={() => { delFn(record.id) }}>
            注销用户
          </Button>
        </Space>
      ),
    },
  ]

  return (
    <>
      <Form name="basic" layout="inline" onFinish={onFinish} autoComplete="off">
        <span>
          <Form.Item name="userName">
            <Input placeholder="请输入用户姓名" />
          </Form.Item>
        </span>
        <span>
          <Form.Item>
            <Button type="primary" icon={<SearchOutlined />} htmlType="submit">
              查询
            </Button>
          </Form.Item>
        </span>
      </Form>

      <div className="list">
        <Table
          showHeader={true}
          columns={columns}
          dataSource={arr.list}
          scroll={{ x: 'max-content', y:'calc(100vh - 400px)' }}
          rowKey="id"
          pagination={
            {
              showTotal:((total) => {
                return `共 ${total} 条`;
              }),
              pageSize: pageParams.pageSize,
              total: arr.count,
              onChange: pageChange,
              current: pageParams.pageNum
            }
          }
        />
      </div>
    </>
  )
}
export default UserList
