import React, { useEffect, useState } from 'react'
import logoImg from '../assets/log.png'
import { CaretDownOutlined } from '@ant-design/icons'
import { Dropdown, Menu, message } from 'antd'
import defaultFace from '../assets/defaultFace.jpg'
import { useNavigate } from 'react-router-dom'
import { LogoutApi } from '../request/api'
import ModifyPassword from './ModifyPassword'

export default function Header() {
  const navigate = useNavigate()
  const [avatar, setAvatar] = useState(defaultFace)
  const [username, setUsername] = useState('游客')
  const [title, setTitle] = useState('修改密码')
  const [showModifyPassWord, setShowModifyPassWord] = useState(false);

  //模拟componentDidMount
  useEffect(() => {
    let username1 = localStorage.getItem('username')
    let avatar1 = localStorage.getItem('avatar')
    let pwdLevel = localStorage.getItem('pwdLevel')
    if (pwdLevel === '') {
      setTitle('首次登录修改密码')
      setShowModifyPassWord(true)
    }
    setUsername(username1 || '游客')
    if (avatar1) {
      setAvatar('http://47.93.114.103:6688/' + avatar1)
    }
  }, [])

  //退出登陆
  const logout = () => {
    LogoutApi({
    }).then((res) => {
      if (res.result) {
        message.success(res.data)
      } else {
        message.error(res.msg)
      }
      //清空数据
      localStorage.clear()
      //跳转根路径
      setTimeout(() => {
        navigate('/login')
      }, 1500)
    })
  }

  //修改密码
  const editPassword = () => {
    setTitle('修改密码')
    setShowModifyPassWord(true)
  }

  const menu = (
    <Menu>
      <Menu.Item key={1} onClick={editPassword}>
        修改密码
      </Menu.Item>
      <Menu.Item key={2} onClick={logout}>
        退出登陆
      </Menu.Item>
    </Menu>
  )

  return (
    <header>
      <img src={logoImg} alt="" className="logo" />
      <div className="right">
        <Dropdown overlay={menu}>
          <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
            <img src={avatar} className="avatar" alt="" />
            <span>{username}</span>
            <CaretDownOutlined />
          </a>
        </Dropdown>
      </div>
      <ModifyPassword title={title} open={showModifyPassWord} handleCancel={() => setShowModifyPassWord(false)} handleOk={(e) => { setShowModifyPassWord(false); }} />
    </header>
  )
}
