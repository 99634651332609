import { Modal, Button, Popconfirm } from 'antd';  
import React, { useState } from 'react';  
  
const DeleteButton = ({ onDelete, modalContent }) => {  
  const [visible, setVisible] = useState(false);  
  
  const handleOk = () => {  
    // 执行删除操作，并调用传入的onDelete方法  
    onDelete();  
    setVisible(false);  
  };  
  
  const handleCancel = () => {  
    setVisible(false);  
  };  
  
  const showModal = () => {  
    setVisible(true);  
  };  
  
  return (  
    <div> 
     <a onClick={() => showModal()}> 卸载  </a>
      <Modal  
        title="确认卸载?"  
        open={visible}  
        onOk={handleOk}  
        onCancel={handleCancel}  
        footer={[  
          <Button key="back" type="primary" onClick={handleCancel}>  
            取消  
          </Button>,  
          <Button key="submit" type="primary" onClick={handleOk}>  
            确认  
          </Button>,  
        ]}  
      >  
        <p>{modalContent}</p>  
      </Modal>  
    </div>  
  );  
};  
  
export default DeleteButton;