import { Input, Modal, Form, message, Space, Button, Radio} from 'antd';
import React, { useContext,useEffect } from 'react';
import {UpdateOrg} from './api'
import  { RootUpdateContext } from './index'
const RootUpdateModel = (props) => {
    let {isUpdateModalOpen, setIsUpdateModalOpen, selectNode, onSyncLoadData} = useContext(RootUpdateContext);
    const [form] = Form.useForm();

    useEffect(() => {
        if (selectNode) {
            const {id, orgName, description, orgType} = selectNode;
            form.setFieldsValue({
                id:id,
                orgName: orgName,
                description :description,
                orgType:orgType,
            })
        }
      }, [])

    const handleOk = () => {
        setIsUpdateModalOpen(false);
    };
    const handleCancel = () => {
        setIsUpdateModalOpen(false);
    };

    const onFinish = async (values) => {
        // 数据的二次处理 
        const {orgType, parentId} = selectNode;
        const {id, orgName, description} = values
        // 判断type fileList 是匹配的才能正常提交
        const params = {
            id,
            orgType,
            orgName,
            description,
            orgParentId:parentId
        }
        await UpdateOrg(params).then((res) => {
        const {code, msg} = res;
            if (code === '200') {
                onSyncLoadData();
                message.success('组织编辑成功');
                handleOk();
            } else {
                console.log(msg);
                message.error("编辑失败:"+ msg.replace("Service:org-management, ErrorMessage:", ""))
            }
        });
      }
    return (
        <Modal title={'编辑组织'} width={480} open={isUpdateModalOpen}  onCancel={handleCancel} footer={null}>
            <Form  {...{labelCol: {span: 6},wrapperCol: {span: 16,}}} form={form}
                validateTrigger={['onBlur','onChange']} labelAlign={'left'} autoComplete="off"
                onFinish={onFinish}>
                <Form.Item hidden={true} name={'id'}>
                    <Input />
                </Form.Item>
                <Form.Item label='组织类型：' name={'orgType'} >
                    <Radio.Group disabled>
                        <Radio value={'comp'}>职能型</Radio>
                        <Radio value={'temp'}>局指型</Radio>
                    </Radio.Group>
                </Form.Item>
                <Form.Item label='组织名称：' name={'orgName'}  rules={[{ required:true, message:'请输入组织名称'}]}>
                    <Input showCount maxLength={50}/>
                </Form.Item>
                <Form.Item label='组织描述：' name={'description'}  rules={[{ required:false, message:'请输入组织描述'}]}>
                    <Input />
                </Form.Item>
                <div style={{height:12}} />
                <Form.Item wrapperCol={{ span: 9, offset: 9 }}>
                    <Space size={'large'}>
                        <Button htmlType="button" onClick={handleCancel}>
                            关闭
                        </Button>
                        <Button type="primary" htmlType="submit">
                            确认
                        </Button>
                    </Space>
                </Form.Item>
            </Form>
        </Modal>

    );
}

export default RootUpdateModel;