import {
  PieChartOutlined, TeamOutlined,
  UserOutlined,
  AppstoreOutlined,
  ProjectOutlined, AccountBookOutlined
} from '@ant-design/icons'
import { Menu } from 'antd'
import React, { useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'

export default function Aside() {
  const navigate = useNavigate()
  const location = useLocation()
  const [defaultKey, setDefaultKey] = useState('')

  //一般加空数组就是为了模仿mounted
  useEffect(() => {
    let path = location.pathname
    let key = path.split('/')[1]
    setDefaultKey(key)
  }, [])

  const onClick = (e) => {
    navigate('/' + e.key)
    setDefaultKey(e.key)
  }
  return (
    <Menu
      onClick={onClick}
      style={{
        width: 160,
      }}
      selectedKeys={[defaultKey]}
      mode="inline"
      className="aside"
      theme="dark">
      <Menu.Item key="operatorList">
        <TeamOutlined />
        <span>运营管理</span>
      </Menu.Item>
      <Menu.Item key="userList">
        <UserOutlined />
        <span>用户管理</span>
      </Menu.Item>
      <Menu.Item key="appList">
        <AppstoreOutlined />
        <span>应用管理</span>
      </Menu.Item>
      <Menu.Item key="productList">
        <ProjectOutlined />
        <span>产品管理</span>
      </Menu.Item>
      <Menu.Item key="authList">
        <AccountBookOutlined />
        <span>授权中心</span>
      </Menu.Item>
      <Menu.SubMenu
        icon={<PieChartOutlined />}
        title={
          <span>组织中心</span>
        }
      >
        <Menu.Item key="org">组织结构</Menu.Item>
        <Menu.Item key="comp">企业客户/项目</Menu.Item>
      </Menu.SubMenu>
    </Menu>
  )
}
