import { Input, Modal, Form, message, Space, Button, Radio } from 'antd';
import React, { useContext,useState, useEffect} from 'react';
import {AddOrg} from './api'
import  { RootContext } from './index'

const loginId = localStorage.getItem('id');

const RootCreateModel = (props) => {
    let {isModalOpen, setIsModalOpen, onSyncLoadData} = useContext(RootContext);
    const [form] = Form.useForm();
    const [radioValue, setRadioValue] = useState('comp');

    useEffect(() => {
        form.setFieldsValue({orgType:radioValue})
      }, [])

    const onChange = (e) => {
        form.setFieldsValue({ orgType: e.target.value });
        setRadioValue(e.target.value)
        return  e.target.value;
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const onFinish = async (values) => {
        // 数据的二次处理 
        const { orgName, description} = values
        // 判断type fileList 是匹配的才能正常提交
        const params = {
            orgName,
            orgType:radioValue,
            description,
            category:1,
        }
        await AddOrg(params, {headers: 
            {
            'userCode': loginId,
            }
        }).then((res) => {
        const {code, msg} = res;
        if (code === '200') {
            onSyncLoadData();
            message.success('新增成功');
            handleOk();
        } else {
            message.error("新增失败:"+ msg.replace("Service:org-management, ErrorMessage:", ""))
            console.log(msg);
        }
        });
      }
    return (
        <Modal title={'新建一级组织'} width={480} open={isModalOpen}  onCancel={handleCancel} footer={null}>
            <Form  {...{labelCol: {span: 6},wrapperCol: {span: 16,}}} form={form}
                validateTrigger={['onBlur','onChange']} labelAlign={'left'} autoComplete="off"
                onFinish={onFinish}>
                <Form.Item label='组织类型：' name={'orgType'} rules={[{ required:true}]}>
                    <Radio.Group defaultValue={'comp'} onChange={onChange} value={radioValue}>
                        <Radio value={'comp'}>职能型</Radio>
                        <Radio value={'temp'}>局指型</Radio>
                    </Radio.Group>
                </Form.Item>
                <Form.Item label='组织名称：' name={'orgName'}  rules={[{ required:true, message:'请输入组织名称'}]}>
                    <Input showCount maxLength={50}/>
                </Form.Item>
                <Form.Item label='组织描述：' name={'description'}  rules={[{ required:false, message:'请输入组织描述'}]}>
                    <Input />
                </Form.Item>
                <div style={{height:12}} />
                <Form.Item wrapperCol={{ span: 9, offset: 9 }}>
                    <Space size={'large'}>
                        <Button htmlType="button" onClick={handleCancel}>
                            关闭
                        </Button>
                        <Button type="primary" htmlType="submit">
                            确认
                        </Button>
                    </Space>
                </Form.Item>
            </Form>
        </Modal>

    );
}

export default RootCreateModel;