import React, { useState, useEffect } from 'react'
import { Button, Form, Input, message } from 'antd'
import './less/login.less'
import logoImg from '../assets/log.png'
import { LockOutlined, MobileOutlined, SecurityScanOutlined } from '@ant-design/icons'
import { useNavigate } from 'react-router-dom'
import { LoginApi, GetSmsApi } from '../request/api'

export default function Login() {
  const navigate = useNavigate()
  const [form] = Form.useForm();
  const [showCaptcha, setShowCaptcha] = useState(true)
  const [smsValidProps, setSmsValidProps] = useState({});
  const [phoneStauts, setPhoneStatus] = useState(false);

  // TODO: 设置验证码的错误状态
  const setSmsError = (val) => {
    setSmsValidProps({
      validateStatus: 'error',
      help: val
    })
  }
  let [timer, setTimer] = useState(60);

  const startTimer = () => {
    setTimer(--timer)
    const t = setInterval(() => {
      if (timer <= 0) {
        clearInterval(t);
        setTimer(60)
      } else {
        setTimer(--timer)
      }
    }, 1000)
  }

  const getSmsCode = () => {
    const mobile = form.getFieldValue('mobile')
    if (!verifyPhoneNum(mobile)) {
      message.error("请输入正确手机号")
      return
    }
    if (timer < 60) return;
    GetSmsApi({
      mobile: mobile,
      userType: '1',
      smsType: 1, // 1: 登录 2: 注册 3: 重置
    }).then((res) => {
      if (res.result) {
        message.success(res.data)
        startTimer()
      } else {
        message.error(res.msg)
      }
    })
  }

  useEffect(() => {
  }, [showCaptcha]) 

  const verifyPhoneNum = (phoneNum) => {
    let phoneReg = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
    if (!phoneReg.test(phoneNum)) {
        return false
    }
    return true;
}

  const handlePhoneChange = (e) => {
    setPhoneStatus(/^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/.test(e.target.value))
  }

  const onFinish = (values) => {
    LoginApi({
      password: values.password,
      mobile: values.mobile,
      // verificationCode: values.verificationCode,
      loginWay: '12',
      userType: '1'
    }).then((res) => {
      console.log(res)
      if (res.result) {
        let data = res.data;
        console.log(data)

        message.success(res.msg)
        //存储数据
        localStorage.setItem('userToken', data['userToken'])
        localStorage.setItem('userType', data.userType || '')
        localStorage.setItem('username', data.userName || '')
        localStorage.setItem('realName', data.realName || '')
        localStorage.setItem('id', data.id)
        localStorage.setItem('pwdLevel', data.pwdLevel || '')
        //跳转根路径
        setTimeout(() => {
          navigate('/operatorList')
        }, 1500)
      } else {
        message.error(res.msg)
      }
    }) //跨域问题
  }

  return (
    <div className="login">
      <div className="login_box">
        <img src={logoImg} alt="" />
        <Form
          form={form}
          name="basic"
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          autoComplete="off">
          <Form.Item
            name="mobile"
            rules={[
              {
                required: true,
                message: '请输入手机号',
              },
            ]}>
            <Input
              size="large"
              prefix={<MobileOutlined />}
              placeholder="请输入手机号"
              onChange={handlePhoneChange} 
            />
          </Form.Item>

          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: '请输入密码',
              },
            ]}>
            <Input.Password
              size="large"
              prefix={<LockOutlined />}
              placeholder="请输入密码"
            />
          </Form.Item>

          {/* <Form.Item name="verificationCode" required rules={[{ required: true, message: '请输入验证码' }]}  {...smsValidProps} >
            <Input 
              size="large"
              placeholder="请输入验证码"
              suffix={<span className={`${!phoneStauts || timer < 60 ? 'getcode-disable' : 'getcode'}`} onClick={getSmsCode}> {timer === 60 ? '获取验证码' : `${timer}s 后重新获取`}   </span>}
              prefix={<SecurityScanOutlined />}
            />
          </Form.Item> */}

          <Form.Item>
            <Button size="large" type="primary" htmlType="submit" block>
              登陆
            </Button>
          </Form.Item>
          <Form.Item>
            <a>申请账号?联系系统管理员：李林栖 18182639820</a>
          </Form.Item>
        </Form>
      </div>
    </div>
  )
}
