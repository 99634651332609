import { Input, Modal, Form, message, Space, Button, TreeSelect} from 'antd';
import React, { useContext, useEffect, useState} from 'react';
import {BatchAddOrgs} from './api'
import  { ChildMountContext } from './index'
import { MountOrgs, checkAccountingDate } from './api'

const loginId = localStorage.getItem('id');
const dataList = [];


  const convertDataToNewFormat= (data) => {  
    return data.filter((item) => {
      const { orgType } = item; 
      return orgType === 'comp' || orgType === 'temp';
    }).map(item => {  
      const { id, orgName, children, category } = item;  
      let cateCode = "非项目"
      let disableCheckbox = true
      if (category === 3) {
        cateCode = "项目"
        disableCheckbox = false
      }
      const newChildren = children ? convertDataToNewFormat(children) : null;  
      return {  
        value: `${orgName}-${id}-${cateCode}`, 
        label: orgName,  
        children: newChildren,
        disabled: disableCheckbox 
      };  
    });    
  }


  const convertDataToCache= (data) => {  
    const result = [];  
    
    function traverse(item) { 
      let cateCode = "非项目"
      if (item.category === 3) {
        cateCode = "项目"
      }  
      const key = `${item.orgName}-${item.id}-${cateCode}`;   
      const value = item.id;
      result.push({ key, value });  
    
      if (item.children) {  
        item.children.forEach(it => {  
          traverse(it); 
        }); 
      }  
    }  
    data.forEach(item => {  
      traverse(item); 
    });  
    
    return result;    
  }


// const CascaderFieldNames = {label: 'orgName', value: 'id', children: 'children' }

const ChildMountModel = (props) => {
    let {isMountModalOpen, setIsMountModalOpen, refreshChildList, setVisible, setAccountingDate, onSyncLoadData} = useContext(ChildMountContext);
    const {id, orgName, orgType} = props.selectNode;
    const treeData = convertDataToNewFormat(props.treeData)
    const treeIndex = convertDataToCache(props.treeData)
    const [form] = Form.useForm();
    useEffect(() => {
      form.setFieldsValue({parentOrg: orgName})
    }, [])
    const handleOk = () => {
      setIsMountModalOpen(false);
    };
    
    const handleCancel = () => {
      setIsMountModalOpen(false);
    };

    const onFinish = async (values) => {
      // 数据的二次处理
      const { mount} = values
      var index = mount.findIndex((mountId)=>(mountId === id))
      if (index > -1) {
        message.error('不能挂载自己');
        return;
      }
      const foundItems = treeIndex.filter(item => mount.includes(item.key));
      const mountOrgIds = foundItems.map(item => item.value);   
      const params = {
          orgId:id,
          orgType:orgType,
          mountOrgIds:mountOrgIds,
      }
      MountOrgs(params, {
        headers:{
          'userCode': loginId,
        }
      }).then((res) => {
        message.success('下级组织挂载成功');
        // 刷新下级组织列表
        onSyncLoadData();
        refreshChildList(id, orgType);
        handleOk();
        checkAccountingDate(id).then((res) => {
          console.info(res)
          const {code, data, msg} = res;
          if (code === '200') {
            const {isEquals, max} = data;
            if (isEquals === 0) {
              setAccountingDate(max)
              setVisible(true)
            }
          }
        })
      }).catch(err => {
        if (err && err.message) {
          console.log(err.message);
        }
        message.success('下级组织挂载失败');
      });
      
    }
    return (
    
      <>
      <dev>
      <Modal title={'挂载下级组织'} width={480} open={isMountModalOpen} onCancel={handleCancel} footer={null}>
          <Form form={form} {...{labelCol: {span: 6},wrapperCol: {span: 16,}}}  
            validateTrigger={['onBlur','onChange']} labelAlign={'left'} autoComplete="off"
            onFinish={onFinish}>
              <Form.Item  label='上级组织：' name='parentOrg' rules={[{ required:true}]}>
                <Input disabled/>
              </Form.Item>
              <Form.Item label='挂载组织：' name={'mount'}  rules={[{ required:true, message:'请选择挂载组织'}]}>
                  <TreeSelect
                      style={{
                      width: '100%',
                      }}
                      multiple
                      dropdownStyle={{
                      maxHeight: 400,
                      overflow: 'auto',
                      }}
                      showSearch={true}
                      // fieldNames={CascaderFieldNames}
                      treeData={treeData}
                      placeholder="请选择挂载组织"
                    />
              </Form.Item>
              <Form.Item wrapperCol={{ span: 9, offset: 9 }}>
                    <Space size={'large'}>
                        <Button htmlType="button" onClick={handleCancel}>
                            关闭
                        </Button>
                        <Button type="primary" htmlType="submit">
                            确认
                        </Button>
                    </Space>
                </Form.Item>
          </Form>
      </Modal>
      </dev>
      
      </>
      

    );
}

export default ChildMountModel;