import React, { useState, useEffect } from 'react'
import '../../pages/less/List.less'
import { Table, Button, Form, Input } from 'antd'
import { QueryAppListApi } from '../../request/api'
import { SearchOutlined } from '@ant-design/icons'
import 'moment/locale/zh-cn'


export default function OperatorList() {
  //应用列表数组
  const [arr, setArr] = useState()

  //请求应用列表
  useEffect(() => {
    QueryAppListApi({
    }).then((res) => {
      if (res.result) {
        let newArr = undefined
        if (res.data === null) {
          newArr = null
        } else {
          newArr = JSON.parse(
            JSON.stringify(res.data.data)
          )
        }
        setArr(newArr)
      }
    })
  }, [])
  const onFinish = (values) => {
    QueryAppListApi({
      appName: values.appName
    }).then((res) => {
      if (res.result) {
        let newArr = undefined
        if (res.data === null) {
          newArr = null
        } else {
          newArr = JSON.parse(
            JSON.stringify(res.data.data)
          )
        }
        setArr(newArr)
      }
    })
  }

  //每一列
  const columns = [
    {
      title: '编号',
      dataIndex: 'id',
      key: 'id',
      width: 60,
      fixed: 'left',
    },
    {
      title: '应用秘钥',
      dataIndex: 'appSecret',
      key: 'appSecret',
      width: 90,
      fixed: 'left',
    },
    {
      title: '应用名称',
      dataIndex: 'appName',
      key: 'appName',
      width: 90,
    },
    {
      title: '应用描述',
      dataIndex: 'appDesc',
      key: 'appDesc',
      width: 90,
    },
    {
      title: '应用状态',
      dataIndex: 'appStatusDesc',
      key: 'appStatusDesc',
      width: 90,
    },
    {
      title: '联系人电话',
      dataIndex: 'contactMobile',
      key: 'contactMobile',
      width: 100,
    },
    {
      title: '联系人姓名',
      dataIndex: 'contactName',
      key: 'contactName',
      width: 105,
    },
    {
      title: '创建时间',
      dataIndex: 'createTime',
      key: 'createTime',
      width: 105,
    }
  ]

  return (
    <>
      <Form name="basic" layout="inline" onFinish={onFinish} autoComplete="off">
        <Form.Item name="appName">
          <Input placeholder="请输入应用名称" />
        </Form.Item>
        <Form.Item>
          <Button type="primary" icon={<SearchOutlined />} htmlType="submit">
            查询
          </Button>
        </Form.Item>
      </Form>

      <div className="list">
        <Table
          showHeader={true}
          columns={columns}
          dataSource={arr}
          scroll={{ x: 'max-content' }}
          rowKey="id"
        />
      </div>
    </>
  )
}
