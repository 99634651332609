import React, { useEffect, useState } from 'react';
import { Form, message, Modal, Input } from 'antd';
import { UpdatePasswordApi } from '../request/api'

const labelControl = {
  labelCol: { span: 5 },
  wrapperCol: { span: 16 }
}


function ModifyPassword(props) {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const rule = [
    {
      type: 'string',
      max: 16,
      min: 8,
      required: true,
      pattern: /((?=.*\d)(?=.*\D)|(?=.*[a-zA-Z])(?=.*[^a-zA-Z]))(?!^.*[\u4E00-\u9FA5].*$){8,16}/,
      message: '请输入8-16位密码，数字、字母和符号至少包含两种'
    }
  ];

  const checkPwd = (e) => {
    const isSpecial = (a) => {
      let reg = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/
      if (reg.test(a)) {
        return true;
      } else {
        return false;
      }
    }
    let s = e;
    if (isSpecial(s)) {
      console.log('密码中允许出现数字、大写字母、小写字母，但至少包含其中3种')
      return '3';
    }
    return '2'
  }

  const handleOk = async () => {
    const e = await form.validateFields();
    if (e.newPassword !== e.newPassword1) return message.error("两次输入的密码不一致，请重新输入");
    if (e.password === e.newPassword) return message.error("新密码不能和原密码相同，请重新输入");
    setLoading(true)
    let id = localStorage.getItem('id')
    const { result, data, msg } = await UpdatePasswordApi({
      id,
      password: e.password,
      newPassword: e.newPassword,
      pwdLevel: checkPwd(e.newPassword)
    });
    if (result) {
      message.success(msg)
      localStorage.setItem('pwdLevel', checkPwd(e.newPassword))
      props.handleOk(e);
    } else {
      message.error(msg)
    }
    setLoading(false)
  }

  useEffect(() => {
    form.resetFields();
  }, [props.open, form]);

  return (
    <Modal title={props.title} open={props.open} onCancel={props.handleCancel} onOk={handleOk} confirmLoading={loading}>
      <Form form={form} autoComplete="off" labelCol={{ span: 6 }} name="control-hooks" {...labelControl} >
        <Form.Item  label="原密码" name="password" required rules={[{ required: true, message: '请输入原密码' }]} getValueFromEvent={(e) => e.target.value.replace(/(^\s*)|(\s*$)/g, '')}>
          <Input.Password className='user-center-input' placeholder="请输入原密码" />
        </Form.Item>
        <Form.Item  label="新密码" name="newPassword" required rules={rule} getValueFromEvent={(e) => e.target.value.replace(/(^\s*)|(\s*$)/g, '')}>
          <Input.Password className='user-center-input' placeholder="请输入新密码" />
        </Form.Item>
        <Form.Item  label="确认新密码" name="newPassword1" required rules={rule} getValueFromEvent={(e) => e.target.value.replace(/(^\s*)|(\s*$)/g, '')}>
          <Input.Password className='user-center-input' placeholder="请输入新密码" />
        </Form.Item>
      </Form>
  </Modal>
  )
}
export default ModifyPassword;
